import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { publicRoutes, protectedRoutes } from "./Routes";
import PublicRoute from "./PublicRoute";
import PageRoutes from "./PageRoutes";
import { UserAuthContext } from "../UserAuthContext";
import { Login } from "../pages/login";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function AppRoutes() {
	return (
		<UserAuthContext>
			<ToastContainer
				position="top-center"
				autoClose={3000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="light"
				limit={3}
			/>
			<Switch>
				{publicRoutes.map((route, i) => {
					return (
						<PublicRoute
							key={i}
							path={route.path}
							component={route.component}
							redirectTo={"/home"}
							exact={route.exact}
						/>
					);
				})}
				<PageRoutes />
			</Switch>
		</UserAuthContext>
	);
}

export default AppRoutes;

import React from "react";

function Container({ children, Tabs, tabIndex }) {
	return (
		<div className="index_area clearfix">
			<Tabs index={tabIndex} />
			<div className="tab-content tabs_content shadow-lg clearfix">{children}</div>
		</div>
	);
}

export default Container;

import React from "react";
import { HashRouter } from "react-router-dom";
import AppRoutes from "./routes/AppRoutes";
import "./css/bootstrap.css";
import "./css/pv1.css";
import "./css/pv2.css";
import "./css/pv_icon1.css";
import "./css/pv_icon2.css";
import "./css/component.css";
import "./css/pages1.css";
import "./css/pages2.css";
import "./css/global.css";
import "react-datepicker/dist/react-datepicker.css";

function App() {
	console.log(process.env.PUBLIC_URL);
	return (
		<HashRouter>
			<AppRoutes />
		</HashRouter>
	);
}

export default App;

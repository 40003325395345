import React, { useState } from "react";
import { Route, Switch } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import { protectedRoutes } from "./Routes";
import { Login } from "../pages/login";
import Index from "../pages/index";
import { AuthContext } from "../UserAuthContext";
import { permissionRoles } from "../roles";

export default function PageRoutes() {
	const { state } = React.useContext(AuthContext);

	if (state.pass && state.user) {
		const routes = protectedRoutes.filter((route) =>
			route.roles.some((i) => permissionRoles[i] === state.user.type)
		);
		return (
			<Index>
				<Switch>
					{routes.map((route, i) => {
						return (
							<ProtectedRoute
								key={i}
								path={route.path}
								component={route.component}
								exact={route.exact}
								redirectTo={"/login"}
							/>
						);
					})}
				</Switch>
			</Index>
		);
	} else {
		return <Route component={Login} />; //當沒有配對路由導向
	}
}

import React from "react";
import { api_token } from "./API";
export const AuthContext = React.createContext({ pass: false, user: null });
export function UserAuthContext(props) {
	const [state, setState] = React.useState({ pass: false, user: null });

	React.useEffect(() => {
		checkToken();
	}, []);

	const checkToken = () => {
		if (localStorage.getItem("dct-token")) {
			api_token()
				.then((res) => {
					console.log(res.data);
					setState({ ...state, pass: true, user: res.data });
					// setState({ ...state, pass: true, user: { account: "test", type: "manager" } });
				})
				.catch((error) => {
					localStorage.removeItem("dct-token");
					setState({ ...state, pass: false, user: null });
				});
		} else {
			setState({ ...state, pass: false, user: null });
		}
	};

	return (
		<AuthContext.Provider value={{ state, checkToken }}>{props.children}</AuthContext.Provider>
	);
}

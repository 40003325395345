import React from "react";
import { UserTabs } from "../../layout/Tabs/UserTabs";
import Container from "../../layout/Container";
import { makeStyles, Divider, IconButton } from "@material-ui/core";
import { Form, Col, Row, Button, InputGroup, FormControl } from "react-bootstrap";
import Table from "../../components/common/Table";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import ForwardIcon from "@material-ui/icons/Forward";
import {
	api_getAllUsers,
	api_editComputer,
	api_deleteUser,
	api_editToCompany,
	api_editRemarks,
	api_editUserPoint
} from "../../API";
import NormalModal from "../../components/modals/NormalModal";
import { AuthContext } from "../../UserAuthContext";

const useStyle = makeStyles((theme) => ({
	borderReset: {
		borderRadius: 2,
	},
	resetButton: {
		border: "1px solid #ced4da",
		borderRadius: 2,
		color: "rgba(0, 0, 0, 0.38)",
		"&:active": {
			border: "1px solid #ced4da",
		},
	},
}));

export function UserConsumer(props) {
	const classes = useStyle();
	const [phone, setPhone] = React.useState("");
	const [sourceData, setSourceData] = React.useState([]);
	const [tableData, setTableData] = React.useState([]);
	const [loading, setLoading] = React.useState(true);
	const [currentData, setCurrentData] = React.useState({});
	const [modal, setModal] = React.useState('');
	const { state } = React.useContext(AuthContext);

	const normalColumns = [
		{
			Header: "FB帳號",
			extraClass: "text-center",
			accessor: "FBname",
			disableSortBy: true,
		},
		{
			Header: "帳號",
			extraClass: "text-center",
			accessor: "name",
			disableSortBy: true,
		},
		{
			Header: "手機",
			extraClass: "text-center",
			accessor: "phone",
			disableSortBy: true,
		},
		{
			Header: "VIP點數",
			extraClass: "text-center",
			accessor: "vip",
			disableSortBy: true,
		},
		{
			Header: "餘額",
			extraClass: "text-center",
			accessor: "point",
			disableSortBy: true,
		},
		{
			Header: "修改餘額",
			accessor: (row) => row,
			Cell: ({ row }) => {
				return state?.user?.type === 'admin' ? (
					<div
						className="text-center btn_tool"
						style={{ cursor: "pointer" }}
						onClick={() => showModal("editPoint", row.original)}
					>
						<CreateIcon />
					</div>
				) : <></>;
			},

			disableSortBy: true,
		},
		{
			Header: "電腦配備",
			accessor: (row) => row,
			Cell: ({ row }) => {
				return (
					<div
						className="text-center btn_tool"
						style={{ cursor: "pointer" }}
						onClick={() => showModal("device", row.original)}
					>
						<CreateIcon />
					</div>
				);
			},
			disableSortBy: true,
		},
		{
			Header: "備註",
			extraClass: "text-center",
			accessor: (row) => row,
			Cell: ({ row }) => {
				return (
					<div className="d-flex justify-content-center align-items-center">
						<div
							className="text-center btn_tool d-flex justify-content-center align-items-center"
							style={{ cursor: "pointer" }}
							onClick={() => showModal("remarks", row.original)}
						>
							{row.original.remarks ? <div>{row.original.remarks}</div> : <CreateIcon />}
						</div>
					</div>
				);
			},
			disableSortBy: true,
		},
		{
			Header: "廠商",
			accessor: (row) => row,
			Cell: ({ row }) => {
				return (
					<div
						className="text-center btn_tool"
						style={{ cursor: "pointer" }}
						onClick={() => onEditToCompany(row.original)}
					>
						<ForwardIcon />
					</div>
				);
			},
			disableSortBy: true,
		},
	];

	const adminColumns = [
		...normalColumns,
		{
			Header: "刪除",
			accessor: (row) => row,
			Cell: ({ row }) => {
				return (
					<div
						className="text-center btn_tool"
						style={{ cursor: "pointer" }}
						onClick={() => showModal("delete", row.original)}
					>
						<DeleteIcon />
					</div>
				);
			},
			disableSortBy: true,
		},
	];

	const columns = state?.user?.type === "admin" ? adminColumns : normalColumns;

	React.useEffect(() => {
		reload();
	}, []);

	const reload = () => {
		api_getAllUsers()
			.then((res) => {
				setSourceData(res.data);
				setTableData(res.data);
				setLoading(false);
			})
			.catch((error) => {
				alert(error?.response ? error.response.data.msg : error);
			});
	};

	const onPhoneChange = (e) => {
		setPhone(e.target.value);
	};

	const onSearch = (event) => {
		event.preventDefault();
		const queryData = phone?.length ? sourceData.filter((i) => i.phone === phone) : sourceData;
		setTableData(queryData);
	};

	const onReset = () => {
		setTableData(sourceData);
		setPhone("");
	};

	const clearData = () => {
		setCurrentData({});
	};

	const showModal = (type, data) => {
		setCurrentData(data);
		setModal(type);
	};

	const closeModal = () => {
		setModal('');
		clearData();
	};

	const onCurrentDataChange = (key) => {
		return (e) => {
			setCurrentData({
				...currentData,
				[key]: e.target.value,
			});
		};
	};

	const onModifyDevice = () => {
		const data = {
			userID: currentData.userID,
			computer: currentData.computer,
		};
		api_editComputer(data)
			.then((res) => {
				reload();
				closeModal();
			})
			.catch((error) => {
				alert(error?.response ? error.response.data.msg : error);
			});
	};

	const onEditPoint = () => {
		const data = {
			userid: currentData.userID,
			point: parseFloat(currentData.point),
		};
		api_editUserPoint(data)
			.then(() => {
				reload();
				closeModal();
			})
			.catch((error) => {
				alert(error?.response ? error.response.data.msg : error);
			});
	};

	const onDeleteUser = () => {
		const data = {
			userID: currentData.userID,
		};
		api_deleteUser(data)
			.then((res) => {
				reload();
				closeModal();
			})
			.catch((error) => {
				alert(error?.response ? error.response.data.msg : error);
			});
	};

	const onEditRemarks = () => {
		const data = {
			userID: currentData.userID,
			remarks: currentData.remarks,
		};
		api_editRemarks(data)
			.then((res) => {
				reload();
				closeModal();
			})
			.catch((error) => {
				alert(error?.response ? error.response.data.msg : error);
			});
	};

	const onEditToCompany = (row) => {
		const data = {
			userID: row.userID,
			is_company: true,
		};
		api_editToCompany(data)
			.then((res) => {
				if (res.data.status === "ok") {
					alert(`已將 ${row.FBname} 設為廠商`);
				}
			})
			.catch((error) => {
				alert(error?.response ? error.response.data.msg : error);
			});
	};

	return (
		<Container Tabs={UserTabs} tabIndex={1}>
			<Form onSubmit={onSearch}>
				<Form.Row className="align-items-center">
					<Col xs="auto">
						<InputGroup>
							<InputGroup.Prepend>
								<InputGroup.Text className={classes.borderReset}>手機</InputGroup.Text>
							</InputGroup.Prepend>
							<FormControl
								placeholder="請輸入手機號碼"
								className={`${classes.inputBox} ${classes.borderReset}`}
								value={phone}
								onChange={onPhoneChange}
							/>
						</InputGroup>
					</Col>
					<Col xs="auto">
						<Button variant="info" className={classes.borderReset} type="submit">
							查詢帳號
						</Button>
					</Col>
					<Col xs="auto">
						<IconButton className={classes.resetButton} onClick={onReset} size="small">
							<AutorenewIcon />
						</IconButton>
					</Col>
				</Form.Row>
			</Form>
			<Divider className="mt-3 mb-4" />
			<Row>
				<div className="col-12">
					<div className="work_area">
						<Table columns={columns} data={tableData} loading={loading} />
					</div>
				</div>
			</Row>
			<NormalModal
				show={modal === 'device'}
				title={"電腦配備"}
				onSubmit={onModifyDevice}
				onClose={closeModal}
				onExited={clearData}
				centered
			>
				<Form>
					<Col>
						<textarea
							cols="40"
							rows="5"
							className="form-control"
							placeholder="請輸入電腦設備資訊"
							value={currentData?.computer}
							onChange={onCurrentDataChange("computer")}
						/>
					</Col>
				</Form>
			</NormalModal>
			<NormalModal
				show={modal === 'remarks'}
				title={"更新備註"}
				onSubmit={onEditRemarks}
				onClose={closeModal}
				onExited={clearData}
				centered
			>
				<Form>
					<Col>
						<textarea
							cols="40"
							rows="5"
							className="form-control"
							placeholder="請輸入備註"
							value={currentData?.remarks}
							onChange={onCurrentDataChange("remarks")}
						/>
					</Col>
				</Form>
			</NormalModal>
			<NormalModal
				show={modal === 'editPoint'}
				title={"修改員工餘額"}
				onSubmit={onEditPoint}
				onClose={closeModal}
				onExited={clearData}
				centered
			>
				<Form>
					<Form.Group as={Row}>
						<Form.Label column sm={3}>
							員工餘額
						</Form.Label>
						<Col sm={9}>
							<FormControl
								placeholder="請輸入員工餘額"
								value={currentData?.point}
								onChange={onCurrentDataChange("point")}
							/>
						</Col>
					</Form.Group>
				</Form>
			</NormalModal>
			<NormalModal
				show={modal === 'delete'}
				title={`刪除使用者`}
				onSubmit={onDeleteUser}
				onClose={closeModal}
				onExited={clearData}
				centered
			>
				<p>
					{"確定刪除此使用者"}
					<b className="ml-2">{currentData?.FBname}</b>
				</p>
			</NormalModal>
		</Container>
	);
}

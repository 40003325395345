import React from "react";
import { ManageTabs } from "../../layout/Tabs/ManageTabs";
import Container from "../../layout/Container";
import { makeStyles, Divider, IconButton } from "@material-ui/core";
import { Form, Col, Row, Button, InputGroup, FormControl } from "react-bootstrap";
import Table from "../../components/common/Table";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import CreateIcon from "@material-ui/icons/Create";
import DatePicker from "react-datepicker";
import { api_getAllEmployees, api_getSalaryRecord, api_writeOff, api_editPoint } from "../../API";
import moment from "moment";
import NormalModal from "../../components/modals/NormalModal";
import { AuthContext } from "../../UserAuthContext";
import { formateDateToUTC_8 } from "../../utils/date";

const useStyle = makeStyles((theme) => ({
	borderReset: {
		borderRadius: 2,
	},
	resetButton: {
		border: "1px solid #ced4da",
		borderRadius: 2,
		color: "rgba(0, 0, 0, 0.38)",
		"&:active": {
			border: "1px solid #ced4da",
		},
	},
}));

export function SalaryRecord(props) {
	const classes = useStyle();
	const [phone1, setPhone1] = React.useState("");
	const [phone2, setPhone2] = React.useState("");
	const [employeeSourceData, setEmployeeSourceData] = React.useState([]);
	const [employeeTableData, setEmployeeTableData] = React.useState([]);
	const [recordSourceData, setRecordSourceData] = React.useState([]);
	const [recordTableData, setRecordTableData] = React.useState([]);
	const [loading1, setLoading1] = React.useState(true);
	const [loading2, setLoading2] = React.useState(true);
	const [startDate, setStartDate] = React.useState(null);
	const [endDate, setEndDate] = React.useState(null);
	const [currentData, setCurrentData] = React.useState({});
	const [writeOffModalShow, setWriteOffModalShow] = React.useState(false);
	const [editPointModalShow, setEditPointModalShow] = React.useState(false);
	const { state } = React.useContext(AuthContext);


	const employeeNormalColumn = [
		{
			Header: "帳號",
			extraClass: "text-center",
			accessor: "FBname",
			disableSortBy: true,
		},
		{
			Header: "手機",
			extraClass: "text-center",
			accessor: "phone",
			disableSortBy: true,
		},
		{
			Header: "餘額",
			extraClass: "text-center",
			accessor: "point",
			disableSortBy: true,
		},
		{
			Header: "核銷",
			accessor: (row) => row,
			Cell: ({ row }) => {
				return (
					<div
						className="text-center btn_tool"
						style={{ cursor: "pointer" }}
						onClick={showModal("writeOff", row.original)}
					>
						<CreateIcon />
					</div>
				);
			},
			disableSortBy: true,
		},
	];

	const employeeAdminColumn = [
		...employeeNormalColumn,
		{
			Header: "修改餘額",
			accessor: (row) => row,
			Cell: ({ row }) => {
				return (
					<div
						className="text-center btn_tool"
						style={{ cursor: "pointer" }}
						onClick={showModal("editPoint", row.original)}
					>
						<CreateIcon />
					</div>
				);
			},
			disableSortBy: true,
		},
	];

	const employeeColumn = state.user.type === "admin" ? employeeAdminColumn : employeeNormalColumn;

	const recordColumn = [
		{
			Header: "帳號",
			extraClass: "text-center",
			accessor: "FBname",
			disableSortBy: true,
		},
		{
			Header: "手機",
			extraClass: "text-center",
			accessor: "phone",
			disableSortBy: true,
		},
		{
			Header: "領薪日期",
			extraClass: "text-center",
			accessor: "date",
			disableSortBy: true,
			Cell: ({ value }) => {
				return (
					<div className="d-flex  justify-content-center align-items-center " style={{ gap: '1rem' }}>
						{value === undefined ? '' : formateDateToUTC_8(value)}
					</div>
				)
			},
		},
		{
			Header: "領薪金額",
			extraClass: "text-center",
			accessor: "writeoffPoint",
			disableSortBy: true,
		},
		{
			Header: "餘額",
			extraClass: "text-center",
			accessor: "point",
			disableSortBy: true,
		},
		{
			Header: "備註",
			extraClass: "text-center",
			accessor: "remark",
			disableSortBy: true,
		},
	];

	const DateInput = React.forwardRef(({ value, onClick }, ref) => {
		const start = startDate ? moment(startDate).format("YYYY-MM-DD") : "";
		const end = endDate ? moment(endDate).format("YYYY-MM-DD") : "";
		return (
			<FormControl
				placeholder="請選擇日期範圍"
				className={`${classes.inputBox} ${classes.borderReset} text-center`}
				value={`${start} 到 ${end}`}
				onClick={onClick}
				onChange={() => { }}
			/>
		);
	});

	React.useEffect(() => {
		reload();
	}, []);

	React.useEffect(() => {
		const data = recordSourceData.filter((item) => {
			if (startDate && endDate) {
				return (
					moment(item.date).isSameOrAfter(moment(startDate)) &&
					moment(item.date).isSameOrBefore(moment(endDate))
				);
			} else if (startDate) {
				return moment(item.date).isSameOrAfter(moment(startDate));
			} else if (endDate) {
				return moment(item.date).isSameOrBefore(moment(endDate));
			} else {
				return true;
			}
		});
		setRecordTableData(data);
	}, [startDate, endDate]);

	const reload = () => {
		api_getAllEmployees()
			.then((res) => {
				setEmployeeSourceData(res.data);
				setEmployeeTableData(res.data);
				setLoading1(false);
			})
			.catch((error) => {
				alert(error?.response ? error.response.data.msg : error);
			});
		setTimeout(() => {
			api_getSalaryRecord()
				.then((res) => {
					setRecordSourceData(res.data);
					setRecordTableData(res.data);
					setLoading2(false);
				})
				.catch((error) => {
					alert(error?.response ? error.response.data.msg : error);
				});
		}, 1000);
	};

	const onPhone1Change = (e) => {
		setPhone1(e.target.value);
	};

	const onPhone2Change = (e) => {
		setPhone2(e.target.value);
	};

	const onSearch1 = () => {
		const queryData = employeeSourceData.filter((i) => i.phone === phone1);
		setEmployeeTableData(queryData);
	};

	const onSearch2 = () => {
		const queryData = recordSourceData.filter((i) => i.phone === phone2);
		setRecordTableData(queryData);
	};

	const onReset1 = () => {
		setEmployeeTableData(employeeSourceData);
		setPhone1("");
	};

	const onReset2 = () => {
		setRecordTableData(recordSourceData);
		setPhone2("");
	};

	const onResetDate = () => {
		setStartDate(null);
		setEndDate(null);
	};

	const onDateChange = (dates) => {
		const [start, end] = dates;
		setStartDate(start);
		setEndDate(end);
	};

	const showModal = (type, data) => {
		return () => {
			switch (type) {
				case "writeOff":
					setWriteOffModalShow(true);
					setCurrentData(data);
					break;
				case "editPoint":
					setEditPointModalShow(true);
					setCurrentData(data);
				default:
					break;
			}
		};
	};

	const closeModal = () => {
		setWriteOffModalShow(false);
		setEditPointModalShow(false);
	};

	const onCurrentDataChange = (key) => {
		return (e) => {
			setCurrentData({
				...currentData,
				[key]: e.target.value,
			});
		};
	};

	const clearData = () => {
		setCurrentData({});
	};

	const onWriteOff = () => {
		const data = {
			id: currentData.userID,
			point: parseFloat(currentData.point),
			remark: currentData.remark,
		};
		api_writeOff(data)
			.then((res) => {
				reload();
				closeModal();
			})
			.catch((error) => {
				alert(error?.response ? error.response.data.msg : error);
			});
	};

	const onEditPoint = () => {
		const data = {
			id: currentData.userID,
			point: parseFloat(currentData.point),
		};
		api_editPoint(data)
			.then((res) => {
				reload();
				closeModal();
			})
			.catch((error) => {
				alert(error?.response ? error.response.data.msg : error);
			});
	};
	return (
		<Container Tabs={ManageTabs} tabIndex={1}>
			<Form>
				<Form.Row className="align-items-center">
					<Col xs="auto">
						<InputGroup>
							<InputGroup.Prepend>
								<InputGroup.Text className={classes.borderReset}>手機</InputGroup.Text>
							</InputGroup.Prepend>
							<FormControl
								placeholder="請輸入手機號碼"
								className={`${classes.inputBox} ${classes.borderReset}`}
								value={phone1}
								onChange={onPhone1Change}
							/>
						</InputGroup>
					</Col>
					<Col xs="auto">
						<Button variant="info" className={classes.borderReset} onClick={onSearch1}>
							查詢帳號
						</Button>
					</Col>
					<Col xs="auto">
						<IconButton className={classes.resetButton} onClick={onReset1} size="small">
							<AutorenewIcon />
						</IconButton>
					</Col>
				</Form.Row>
			</Form>
			<Divider className="mt-3 mb-4" />
			<Row>
				<div className="col-12">
					<div className="work_area">
						<Table columns={employeeColumn} data={employeeTableData} loading={loading1} />
					</div>
				</div>
			</Row>
			<Divider className="mt-3 mb-4" />
			<Form>
				<Form.Row className="align-items-center">
					<Col xs="auto">
						<InputGroup>
							<InputGroup.Prepend>
								<InputGroup.Text className={classes.borderReset}>手機</InputGroup.Text>
							</InputGroup.Prepend>
							<FormControl
								placeholder="請輸入手機號碼"
								className={`${classes.inputBox} ${classes.borderReset}`}
								value={phone2}
								onChange={onPhone2Change}
							/>
						</InputGroup>
					</Col>
					<Col xs="auto">
						<Button variant="info" className={classes.borderReset} onClick={onSearch2}>
							查詢帳號
						</Button>
					</Col>
					<Col xs="auto">
						<IconButton className={classes.resetButton} onClick={onReset2} size="small">
							<AutorenewIcon />
						</IconButton>
					</Col>
					<Col xs="auto" className="d-flex align-items-center">
						<div className="mr-2">{"日期範圍:"}</div>
						<DatePicker
							selected={startDate}
							onChange={onDateChange}
							startDate={startDate}
							endDate={endDate}
							selectsRange
							className="border"
							customInput={<DateInput />}
						// monthsShown={2}
						/>
					</Col>
					<Col xs="auto">
						<IconButton className={classes.resetButton} onClick={onResetDate} size="small">
							<AutorenewIcon />
						</IconButton>
					</Col>
				</Form.Row>
			</Form>
			<Divider className="mt-3 mb-4" />
			<Row>
				<div className="col-12">
					<div className="work_area">
						<Table columns={recordColumn} data={recordTableData} loading={loading2} />
					</div>
				</div>
			</Row>
			<NormalModal
				show={writeOffModalShow}
				title={"核銷頁面"}
				onSubmit={onWriteOff}
				onClose={closeModal}
				onExited={clearData}
				centered
			>
				<Form>
					<Col className="mb-3">
						<FormControl
							placeholder="核銷點數"
							value={currentData.point}
							onChange={onCurrentDataChange("point")}
						/>
					</Col>
					<Col>
						<textarea
							cols="40"
							rows="5"
							className="form-control"
							placeholder="備註"
							value={currentData.remark}
							onChange={onCurrentDataChange("remark")}
						/>
					</Col>
				</Form>
			</NormalModal>
			<NormalModal
				show={editPointModalShow}
				title={"修改員工餘額"}
				onSubmit={onEditPoint}
				onClose={closeModal}
				onExited={clearData}
				centered
			>
				<Form>
					<Form.Group as={Row}>
						<Form.Label column sm={3}>
							員工餘額
						</Form.Label>
						<Col sm={9}>
							<FormControl
								placeholder="請輸入員工餘額"
								value={currentData.point}
								onChange={onCurrentDataChange("point")}
							/>
						</Col>
					</Form.Group>
				</Form>
			</NormalModal>
		</Container>
	);
}

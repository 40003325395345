import React from "react";
import { NavLink, Link, useLocation } from "react-router-dom";
import { protectedRoutes } from "../routes/Routes";
import TelegramIcon from "@material-ui/icons/Telegram";

export default function SideNav({ sidebarHover, menuClick, onMouseEnter, onMouseLeave }) {
	const location = useLocation();

	const check_active = (name) => {
		let route = protectedRoutes.find((item) => item.path === location.pathname);
		if (route) {
			return route.path.split("/")[1] === name ? "active" : "";
		} else {
			return "";
		}
	};

	return (
		<nav
			className={
				"sidebar_area js_sidebar clearfix " + (sidebarHover || menuClick ? "hovered" : "")
			}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		>
			<ul className="sidebar_list list-unstyled mb-0">
				<li>
					<NavLink to="/home" className={check_active("home")}>
						<i className="icon-home"></i>
						<span>首頁</span>
					</NavLink>
				</li>
				<li>
					<NavLink to="/consumer" className={check_active("consumer")}>
						<i className="icon-dvr"></i>
						<span>消費者紀錄</span>
					</NavLink>
				</li>
				<li>
					<NavLink to="/manage" className={check_active("manage")}>
						<i className="icon-storage"></i>
						<span>內部管理</span>
					</NavLink>
				</li>
				<li>
					<NavLink to="/users" className={check_active("users")}>
						<i className="icon-settings"></i>
						<span>用戶組</span>
					</NavLink>
				</li>
			</ul>
		</nav>
	);
}

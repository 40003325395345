import React, { useState } from "react";
import Frame from "../layout/Frame";

export default function PageRoutes(props) {
	const [menuClick, setMenuClick] = useState(false);
	const [current_page, set_current_page] = React.useState(1); //目前頁面

	const handleMenuClick = () => {
		setMenuClick(!menuClick);
	};

	return (
		<div>
			<Frame handleMenuClick={handleMenuClick} />
			<main className={"main_area js_main clearfix " + (menuClick ? "active" : "")}>
				{props.children}
			</main>
		</div>
	);
}

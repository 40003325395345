import React from "react";
import { HomeTabs } from "../../layout/Tabs/HomeTabs";
import Container from "../../layout/Container";
import { Card, Paper, makeStyles, Divider } from "@material-ui/core";
import Table from "../../components/common/Table";
import {
	api_getRechargeTotal,
	api_getEmployeeTransferTotal,
	api_getTransactionTotal,
	api_getSalaryTotal,
	api_getCustomerTransferTotal,
	api_getCashflowTotal,
	api_getRevenueTotal,
	api_getCostTotal,
	api_getAllMonthRecharge,
} from "../../API";

const cardData = [
	{
		headerTitle: "總儲值金額",
		footerTitle: "今日儲值金額",
	},
	{
		headerTitle: "總交易金額",
		footerTitle: "今日交易金額",
	},
	{
		headerTitle: "消費者總轉帳金額",
		footerTitle: "今日消費者總轉帳金額",
	},
	{
		headerTitle: "員工總轉帳金額",
		footerTitle: "今日員工總轉帳金額",
	},
	{
		headerTitle: "員工總領薪金額",
		footerTitle: "今日員工總領薪金額",
	},
	{
		headerTitle: "公司分潤總額",
		footerTitle: "************************",
		hideDayTotal: true,
	},
	{
		headerTitle: "金流分潤總額",
		footerTitle: "************************",
		hideDayTotal: true,
	},
	{
		headerTitle: "總成本",
		footerTitle: "************************",
		hideDayTotal: true,
	},
];

const columns = [
	{
		Header: "Months",
		extraClass: "text-center",
		accessor: "Date",
		disableSortBy: true,
	},
	{
		Header: "總儲值金額",
		extraClass: "text-center",
		accessor: "RechargeMonthTotal",
		disableSortBy: true,
	},
	{
		Header: "總交易金額",
		extraClass: "text-center",
		accessor: "TransactionTotal",
		disableSortBy: true,
	},
	{
		Header: "消費者總轉帳金額",
		extraClass: "text-center",
		accessor: "CustomerTransferTotal",
		disableSortBy: true,
	},
	{
		Header: "員工總轉帳金額",
		extraClass: "text-center",
		accessor: "EmployeeTransferTotal",
		disableSortBy: true,
	},
	{
		Header: "員工總領薪金額",
		extraClass: "text-center",
		accessor: "salaryTotal",
		disableSortBy: true,
	},
	{
		Header: "公司分潤總額",
		extraClass: "text-center",
		accessor: "revenueTotal",
		disableSortBy: true,
		Cell: ({ cell }) => <div>{cell.value.toFixed(1)}</div>
	},
	{
		Header: "金流分潤總額",
		extraClass: "text-center",
		accessor: "cashflowTotal",
		disableSortBy: true,
	},
	{
		Header: "總成本",
		extraClass: "text-center",
		accessor: "costTotal",
		disableSortBy: true,
	},
];

const useStyle = makeStyles((theme) => ({
	card: {
		background: "#fafafa",
		marginBottom: "15px",
		borderRadius: "2px",
		padding: "20px 24px 8px",
	},
	cardHeader: {
		"& .title": {
			color: "rgba(0, 0, 0, 0.54)",
			fontSize: "14px",
			lineHeight: "22px",
		},
		"& .total": {
			overflow: "hidden",
			textOverflow: "ellipsis",
			wordBreak: "break-all",
			whiteSpace: "nowrap",
			color: "rgba(0,0,0,.85)",
			marginTop: "4px",
			marginBottom: 0,
			fontSize: "30px",
			lineHeight: "38px",
			height: "38px",
		},
	},
	divider: {
		margin: "10px 0",
	},
	cardFooter: {
		"& span": {
			fontSize: "14px",
			lineHeight: "22px",
		},
		"& .today": {
			marginLeft: "8px",
			color: "rgba(0,0,0,.85)",
		},
	},
}));

export function Console(props) {
	const classes = useStyle();
	const [monthTotals, setMonthTotals] = React.useState([0, 0, 0, 0, 0, 0, 0, 0]);
	const [dayTotals, setDayTotals] = React.useState([0, 0, 0, 0, 0, 0, 0, 0]);
	const [tableData, setTableData] = React.useState([]);
	const [loading, setLoading] = React.useState(true);

	React.useEffect(() => {
		api_getRechargeTotal()
			.then(setCardData(0, "RechargeMonthTotal", "RechargeDayTotal"))
			.catch((error) => {
				alert(error?.response ? error.response.data.msg : error);
			});
		api_getTransactionTotal()
			.then(setCardData(1, "TranscationMonthTotal", "TranscationDayTotal"))
			.catch((error) => {
				alert(error?.response ? error.response.data.msg : error);
			});
		api_getCustomerTransferTotal()
			.then(setCardData(2, "CustomerTransferMonthTotal", "CustomerTransferDayTotal"))
			.catch((error) => {
				alert(error?.response ? error.response.data.msg : error);
			});
		api_getEmployeeTransferTotal()
			.then(setCardData(3, "EmployeeTransferMonthTotal", "EmployeeTransferDayTotal"))
			.catch((error) => {
				alert(error?.response ? error.response.data.msg : error);
			});
		api_getSalaryTotal()
			.then(setCardData(4, "SalaryMonthTotal", "SalaryDayTotal"))
			.catch((error) => {
				alert(error?.response ? error.response.data.msg : error);
			});
		api_getRevenueTotal()
			.then(setCardData(5, "RevenueMonthTotal", ""))
			.catch((error) => {
				alert(error?.response ? error.response.data.msg : error);
			});
		api_getCashflowTotal()
			.then(setCardData(6, "CashflowMonthTotal", ""))
			.catch((error) => {
				alert(error?.response ? error.response.data.msg : error);
			});
		api_getCostTotal()
			.then(setCardData(7, "CostMonthTotal", ""))
			.catch((error) => {
				alert(error?.response ? error.response.data.msg : error);
			});
		api_getAllMonthRecharge()
			.then((res) => {
				setTableData(res.data);
				setLoading(false);
			})
			.catch((error) => {
				alert(error?.response ? error.response.data.msg : error);
				setLoading(false);
			});
	}, []);

	const setCardData = (i, monthKey, dayKey) => {
		return (res) => {
			if (monthKey) {
				setMonthTotals((state) => {
					const newState = [...state];
					newState[i] = res.data[monthKey];
					return newState;
				});
			}
			if (dayKey) {
				setDayTotals((state) => {
					const newState = [...state];
					newState[i] = res.data[dayKey];
					return newState;
				});
			}
		};
	};
	return (
		<Container Tabs={HomeTabs} tabIndex={1}>
			<div className="row">
				{cardData.map((data, i) => {
					return (
						<div className="col-xs col-md-6 col-xl-3" key={i}>
							<Paper className={classes.card} elevation={0}>
								<div className={classes.cardHeader}>
									<div className="metawrap">
										<div className="title">{data.headerTitle}</div>
										<div className="total">{monthTotals[i].toFixed(1)}</div>
									</div>
								</div>
								<Divider className={classes.divider} />
								<div className={classes.cardFooter}>
									<span>{data.footerTitle}</span>
									{!data.hideDayTotal && (
										<span className="today">{dayTotals[i]}</span>
									)}
								</div>
							</Paper>
						</div>
					);
				})}
			</div>
			<Divider className="mt-3 mb-4" />
			<div className="row">
				<div className="col-12">
					<div className="work_area">
						<Table columns={columns} data={tableData} loading={loading} />
					</div>
				</div>
			</div>
		</Container>
	);
}

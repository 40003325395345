import React, { useState } from "react";
import Header from "./Header";
import SideNav from "./SideNav";

export default function Frame(props) {
	const [sidebarHover, setSidebarHover] = useState(false);
	const [menuClick, setMenuClick] = useState(false);

	const handleMenuClick = () => {
		setMenuClick(!menuClick);
		props.handleMenuClick();
	};

	const handleMouseEnter = () => {
		setSidebarHover(true);
	};

	const handleMouseLeave = () => {
		setSidebarHover(false);
	};

	return (
		<React.Fragment>
			<Header
				sidebarHover={sidebarHover}
				menuClick={menuClick}
				handleMenuClick={handleMenuClick}
			/>
			<SideNav
				sidebarHover={sidebarHover}
				menuClick={menuClick}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
			/>
		</React.Fragment>
	);
}

import React from "react";
import { Route, Redirect, useHistory, useLocation } from "react-router-dom";
import { AuthContext } from "../UserAuthContext";

function ProtectedRoute({ path, component, redirectTo, exact }) {
	const { state, checkToken } = React.useContext(AuthContext);

	React.useEffect(() => {
		checkToken();
	}, []);

	if (state.pass) {
		return <Route path={path} component={component} exact={exact} />;
	} else {
		return <Redirect redirectTo={redirectTo} to={redirectTo} />;
	}
}
export default ProtectedRoute;

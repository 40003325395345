import React from "react";
import Container from "../../layout/Container";
import Table from "../../components/common/Table";
import CreateIcon from "@material-ui/icons/Create";
import { Form, Row, Col, FormControl } from "react-bootstrap";
import { HomeTabs } from "../../layout/Tabs/HomeTabs";
import { api_getTradeRules, api_editTradeRule } from "../../API";
import NormalModal from "../../components/modals/NormalModal";


export function TradeRule() {
	const [loading, setLoading] = React.useState(true);
	const [tableData, setTableData] = React.useState([]);
	const [currentData, setCurrentData] = React.useState({});
	const [modifyModalShow, setModifyModalShow] = React.useState(false);
	console.log(tableData)
	const columns = [
		{
			Header: "模式",
			extraClass: "text-center",
			accessor: "title",
			disableSortBy: true,
		},
		{
			Header: "最小交易金額",
			extraClass: "text-center",
			accessor: "min",
			disableSortBy: true,
		},
		{
			Header: "最大交易金額",
			extraClass: "text-center",
			accessor: "max",
			disableSortBy: true,
		},
		{
			Header: "現金%數",
			extraClass: "text-center",
			accessor: "cashflow",
		},
		{
			Header: "公司%數",
			extraClass: "text-center",
			accessor: "company",
		},
		{
			Header: "工程師%數",
			extraClass: "text-center",
			accessor: "engineer",
		},
		{
			Header: "修改",
			accessor: (row) => row,
			Cell: ({ row }) => {
				return (
					<div
						className="text-center btn_tool"
						style={{ cursor: "pointer" }}
						onClick={onShowModifyModal(row.original)}
					>
						<CreateIcon />
					</div>
				);
			},
			disableSortBy: true,
		},
	];

	React.useEffect(() => {
		reload();
	}, []);

	const reload = () => {
		api_getTradeRules()
			.then((res) => {
				const table_value = [];
				const arr = ["rule1", "rule2", "rule3", "rule4", "rule5", "rule6", "rule7"];
				// "0~4鑽",
				// "5~10鑽",
				// "11~24鑽",
				// "25~56鑽",
				// "57~100鑽",
				// "101~150鑽",
				// "151鑽Up",
				for (var i = 0; i <= 6; i++) {
					let key = arr[i];
					table_value.push({
						title: key,
						cashflow: res.data[2][key]["cashflow"],
						company: res.data[2][key]["company"],
						engineer: res.data[2][key]["engineer"],
						min: res.data[2][key]["min"],
						max: res.data[2][key]["max"],
					});
				}
				setTableData(table_value);
				setLoading(false);
			})
			.catch((error) => {
				alert(error?.response ? error.response.data.msg : error);
			});
	};

	const onModify = () => {
		const data = {
			title: currentData.title,
			cashflow: parseFloat(currentData.cashflow),
			company: parseFloat(currentData.company),
			engineer: parseFloat(currentData.engineer),
		};
		api_editTradeRule(data)
			.then((res) => {
				reload();
				closeModal();
			})
			.catch((error) => {
				alert(error?.response ? error.response.data.msg : error);
			});
	};

	const onModifyDataChange = (key) => {
		return (e) => {
			setCurrentData({
				...currentData,
				[key]: e.target.value,
			});
		};
	};

	const onShowModifyModal = (data) => {

		return () => {
			setCurrentData(data);
			setModifyModalShow(true);
		};
	};

	const closeModal = () => {
		setModifyModalShow(false);
	};

	const clearModalData = () => {
		setCurrentData({});
	};

	return (
		<Container Tabs={HomeTabs} tabIndex={5}>
			<div className="row">
				<div className="col-12">
					<div className="work_area">
						<Table columns={columns} data={tableData} loading={loading} />
					</div>
				</div>
			</div>
			<NormalModal
				show={modifyModalShow}
				title={`修改 ${currentData.title}`}
				onClose={closeModal}
				onSubmit={onModify}
				onExited={clearModalData}
				centered
			>
				<Form.Group as={Row}>
					<Form.Label column sm={4}>
						現金%數
					</Form.Label>
					<Col sm={8}>
						<FormControl
							placeholder="0"
							value={currentData.cashflow}
							onChange={onModifyDataChange("cashflow")}
						/>
					</Col>
				</Form.Group>
				<Form.Group as={Row}>
					<Form.Label column sm={4}>
						公司%數
					</Form.Label>
					<Col sm={8}>
						<FormControl
							placeholder="0"
							value={currentData.company}
							onChange={onModifyDataChange("company")}
						/>
					</Col>
				</Form.Group>
				<Form.Group as={Row}>
					<Form.Label column sm={4}>
						工程師%數
					</Form.Label>
					<Col sm={8}>
						<FormControl
							placeholder="0"
							value={currentData.engineer}
							onChange={onModifyDataChange("engineer")}
						/>
					</Col>
				</Form.Group>
			</NormalModal>
		</Container>
	);
}

import React, { Fragment, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, useHistory, useLocation } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import NotificationsRoundedIcon from "@material-ui/icons/NotificationsRounded";
import { Form, Col, Row, Button, InputGroup, FormControl, ProgressBar } from "react-bootstrap";
import { makeStyles } from "@material-ui/core";
import { AuthContext } from "../UserAuthContext";
import NormalModal from "../components/modals/NormalModal";

const useStyle = makeStyles((theme) => ({}));

export default function Header({ sidebarHover, menuClick, handleMenuClick }) {
	const [fullScreen, setFullScreen] = useState(false);
	const [currentData, setCurrentData] = React.useState(null);
	const { checkToken, state } = React.useContext(AuthContext);
	const classes = useStyle();
	const history = useHistory();

	const toggleFullScreen = () => {
		(document.fullScreenElement && null !== document.fullScreenElement) ||
		(!document.mozFullScreen && !document.webkitIsFullScreen)
			? document.documentElement.requestFullScreen
				? document.documentElement.requestFullScreen()
				: document.documentElement.mozRequestFullScreen
				? document.documentElement.mozRequestFullScreen()
				: document.documentElement.webkitRequestFullScreen &&
				  document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT)
			: document.cancelFullScreen
			? document.cancelFullScreen()
			: document.mozCancelFullScreen
			? document.mozCancelFullScreen()
			: document.webkitCancelFullScreen && document.webkitCancelFullScreen();
		setFullScreen(!fullScreen);
	};

	const closeModal = () => {};

	const clearData = () => {
		setCurrentData(null);
	};

	const onCurrentDataChange = (key) => {
		return (e) => {
			setCurrentData({
				...currentData,
				[key]: e.target.value,
			});
		};
	};

	//登出
	const logout = async () => {
		localStorage.removeItem("dct-token");
		checkToken();
		alert("成功登出");
	};

	return (
		<Fragment>
			<header className="header_area clearfix">
				<div className="float-left">
					<button
						id="js_menu"
						className={"btn_menu float-left " + (sidebarHover || menuClick ? "active" : "")}
						onClick={handleMenuClick}
					>
						<i className="icon-menu"></i>
					</button>
					<button className="logo_area float-left" href="./index.html">
						<img className="bg-white" src="./img/pv_logo.png" height="30" alt="" />
					</button>
				</div>
				{/* <h5 style={{ color: "white", position: "absolute", left: "50vw" }}>{get_mode()}</h5> */}
				<ul className="topbar_area list-unstyled float-right mb-0">
					<li>
						<button className="btn_full btn float-left" onClick={toggleFullScreen}>
							<i className={"icon-fullscreen" + (fullScreen ? "_exit" : "")}></i>
						</button>
						{/* <button className="btn_full btn float-left ml-2" onClick={toggleFullScreen}>
              <NotificationsRoundedIcon style={{color: '#9B9FAF', fontSize: 28}}/>
            </button> */}
					</li>
					{/* <li>
						<Dropdown className="user_area float-left">
							<Dropdown.Toggle
								as="a"
								className="btn_select d-flex align-items-center"
								style={{ height: "40px" }}
							>
								<NotificationsRoundedIcon
									style={{ color: "#9B9FAF", fontSize: 30 }}
								/>
							</Dropdown.Toggle>
							<Dropdown.Menu style={{ width: "fit-content" }}>
								<Dropdown.Item>
									<div>
										<i className="icon-check"></i>【花蓮瑞穗一場】需要清洗
									</div>
								</Dropdown.Item>
								<Dropdown.Item>
									<div>
										<i className="icon-check"></i>【pvgulder】用戶帳密已寄出
									</div>
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					</li> */}
					<li>
						<Dropdown className="user_area float-left">
							<Dropdown.Toggle as="a" className="btn_select">
								<span>
									<i className="icon-account_circle float-left"></i>
								</span>
							</Dropdown.Toggle>
							<Dropdown.Menu className="dropdown-menu-right">
								{/* <Dropdown.Item onClick={() => {}}>
									<i className="icon-httpslock"></i>
									<span>
										<div>個人資訊</div>
									</span>
								</Dropdown.Item> */}
								{/* <Dropdown.Item onClick={() => showModal("account")}>
									<i className="icon-httpslock"></i>
									<span>
										<div id="t_changePwd">修改密碼</div>
									</span>
								</Dropdown.Item> */}
								{/* <Dropdown className="dropdown-item dropdown-inner">
									<Dropdown.Toggle
										as="a"
										className="dropdown-item dropdown-submenu"
									>
										<i className="icon-language"></i>
										<span>
											<div id="t_select_lang">語言</div>
										</span>
									</Dropdown.Toggle>
									<Dropdown.Menu className="dropdown-submenu-inner">
										<Dropdown.Item
											className="dropdown-submenu-item"
											onClick={() => {}}
										>
											<i className="icon-language"></i>
											<span>ENGLISH</span>
										</Dropdown.Item>
										<Dropdown.Item
											className="dropdown-submenu-item"
											onClick={() => {}}
										>
											<i className="icon-language"></i>
											<span>繁體中文</span>
										</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown> */}
								<Dropdown.Item modaltoshow="modal_account" onClick={logout}>
									<i className="icon-logout"></i>
									<span>
										{/* 登出 */}
										<div id="t_logout">登出</div>
									</span>
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					</li>
				</ul>
			</header>
		</Fragment>
	);
}

import React, { useRef } from "react";
import { HomeTabs } from "../../layout/Tabs/HomeTabs";
import Container from "../../layout/Container";
import CreateIcon from "@material-ui/icons/Create";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DeleteIcon from "@material-ui/icons/Delete";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { green } from '@material-ui/core/colors';
import { Form, Col, Row, Button, InputGroup, FormControl } from "react-bootstrap";
import NormalModal from "../../components/modals/NormalModal";
import { api_getCode, api_editCodeSendStatus, api_deleteCode, api_addCode } from "../../API";
import { makeStyles, Divider } from "@material-ui/core";
import Table from "../../components/common/Table";
import { AuthContext } from "../../UserAuthContext";
import { toast } from "react-toastify";
import { useNotificationCenter } from "react-toastify/addons/use-notification-center";
import { formateDateToUTC_8 } from "../../utils/date";
const useStyles = makeStyles((theme) => ({
	inputBox: {
		width: "100px !important",
	},
	borderReset: {
		borderRadius: 2,
	},
}));

export function Code() {
	const classes = useStyles();
	const [point, setPoint] = React.useState(0);
	const [vipPoint, setVipPoint] = React.useState(0);
	const [quantity, setQuantity] = React.useState(0);
	const [currentData, setCurrentData] = React.useState({});
	const [modifyModalShow, setModifyModalShow] = React.useState(false);
	const [tableData, setTableData] = React.useState([]);
	const [loading, setLoading] = React.useState(true);
	const { state } = React.useContext(AuthContext);

	const viewerColumns = [
		{
			Header: "兌換者",
			extraClass: "text-center",
			accessor: "converter_Number",
		},
		{
			Header: "序號",
			extraClass: "text-center",
			accessor: "code",
			disableSortBy: true,
			width: "40%",
			Cell: ({ value }) => {
				const pinCodeRef = useRef()
				const handleCopyPinCode = async () => {
					await navigator.clipboard.writeText(pinCodeRef.current.innerText);
					toast.success('複製成功!!', {
						autoClose: 1000,
					})
				}
				return (
					<div className="d-flex  justify-content-center align-items-center p-2 " style={{ gap: '1rem' }}>
						<div ref={pinCodeRef}>{value}</div>
						<FileCopyIcon className="btn_tool" style={{ cursor: 'pointer', fontSize: '1.25rem' }} onClick={handleCopyPinCode} />
					</div>
				)
			}
		},
		{
			Header: "發送狀態",
			extraClass: "text-center",
			accessor: (row) => (row.sendStatus ? "true" : "false"),
			width: "10%",
		},
		{
			Header: "使用狀態",
			extraClass: "text-center",
			accessor: (row) => (row.usedStatus ? "已使用" : "尚未使用"),
			width: "10%",
		},
		{
			Header: "點數",
			extraClass: "text-center",
			accessor: "point",
			width: "10%",
		},
		{
			Header: "VIP點數",
			extraClass: "text-center",
			accessor: "vipPoint",
			width: "10%",
		},

		{
			Header: "產生時間",
			extraClass: "text-center",
			accessor: "date",
			Cell: ({ value }) => {
				return (
					<div className="d-flex  justify-content-center align-items-center " style={{ gap: '1rem' }}>
						{value === undefined ? '' : formateDateToUTC_8(value)}
					</div>
				)
			},
			width: "20%",
		},

		{
			Header: "兌換時間",
			extraClass: "text-center",
			accessor: "exchange_date",
		}

	];

	const normalColumns = [
		...viewerColumns,
		{
			Header: "修改",
			accessor: (row) => row,
			Cell: ({ row, selectedFlatRows }) => {
				return (
					<div
						className="text-center btn_tool"
						style={{ cursor: "pointer" }}
						onClick={onShowModifyModal(row.original)}
					>
						<CreateIcon />
					</div>
				);
			},
			disableSortBy: true,
			showActionSelectRows: true,
			width: "5%",
		},
		{
			Header: "刪除",
			accessor: (row) => row,
			Cell: ({ row }) => {
				return (
					<div
						className="text-center btn_tool"
						style={{ cursor: "pointer" }}
						onClick={onDelete(row.original)}
					>
						<DeleteIcon />
					</div>
				);
			},
			disableSortBy: true,
			Action: ({ row, selectedFlatRows }) => {
				const data = selectedFlatRows.map((i) => i.original.id);
				return (
					<div
						className="text-center btn_tool"
						style={{ cursor: "pointer" }}
						onClick={onDeleteAll(data)}
					>
						<DeleteOutlineIcon />
					</div>
				);
			},
			canAction: true,
			width: "5%",
		},
	];

	const columns = state?.user?.type === "viewer" ? viewerColumns : normalColumns;

	React.useEffect(() => {
		reload();
	}, []);
	const getDateString = (time) => {
		if (time === undefined) return
		let date = new Date(time * 1000)
		const timeFormat = (time) => {
			return time >= 10 ? time : '0' + time
		}
		return (`${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()} ${timeFormat(date.getHours())}:${timeFormat(date.getMinutes())}:${timeFormat(date.getSeconds())}`)

	}
	const reload = () => {
		api_getCode()
			.then((res) => {
				setTableData(res.data.map(code => ({
					...code,
					exchange_date: getDateString(code?.exchange_time?.seconds)
				})));
				setLoading(false);
			})
			.catch((error) => {
				alert(error?.response ? error.response.data.msg : error);
			});
	};

	const onGenerateCode = () => {
		const data = { point, vipPoint, quantity };
		if (loading) return;
		setLoading(true);
		api_addCode(data)
			.then((res) => {
				reload();
			})
			.catch((error) => {
				alert(error?.response ? error.response.data.msg : error);
				reload();
			});
	};

	const onModify = () => {
		const data = { sendStatus: currentData.sendStatus, time: currentData.time };
		api_editCodeSendStatus(data)
			.then((res) => {
				reload();
				closeModal();
			})
			.catch((error) => {
				alert(error?.response ? error.response.data.msg : error);
			});
	};

	const onDelete = (rowData) => {
		return () => {
			api_deleteCode({ id: [rowData.id] })
				.then((res) => {
					reload();
					closeModal();
				})
				.catch((error) => {
					alert(error?.response ? error.response.data.msg : error);
				});
		};
	};

	const onDeleteAll = (data) => {
		return () => {
			api_deleteCode({ id: data })
				.then((res) => {
					reload();
					closeModal();
				})
				.catch((error) => {
					alert(error?.response ? error.response.data.msg : error);
				});
		};
	};

	const onPointChange = (e) => {
		setPoint(e.target.value);
	};

	const onVipPointChange = (e) => {
		setVipPoint(e.target.value);
	};

	const onQuantityChange = (e) => {
		setQuantity(e.target.value);
	};

	const onStatusChange = (e) => {
		setCurrentData({ ...currentData, sendStatus: e.target.value });
	};

	const onShowModifyModal = (data) => {
		return () => {
			setCurrentData(data);
			setModifyModalShow(true);
		};
	};

	const closeModal = () => {
		setModifyModalShow(false);
	};

	const clearModalData = () => {
		setCurrentData({});
	};

	return (
		<Container Tabs={HomeTabs} tabIndex={4}>
			<Form>
				<Form.Row className="align-items-center">
					{/* <Col xs="auto">
						<Form.Label htmlFor="inlineFormInput">Name</Form.Label>
					</Col>
					<Col xs="auto">
						<Form.Control
							className="mb-2"
							id="inlineFormInput"
							placeholder="Jane Doe"
						/>
					</Col> */}
					<Col xs="auto">
						<InputGroup>
							<InputGroup.Prepend>
								<InputGroup.Text className={classes.borderReset}>點數</InputGroup.Text>
							</InputGroup.Prepend>
							<FormControl
								placeholder="0"
								className={`${classes.inputBox} ${classes.borderReset}`}
								value={point}
								onChange={onPointChange}
							/>
						</InputGroup>
					</Col>
					<Col xs="auto">
						<InputGroup>
							<InputGroup.Prepend>
								<InputGroup.Text className={classes.borderReset}>VIP點數</InputGroup.Text>
							</InputGroup.Prepend>
							<FormControl
								placeholder="0"
								className={`${classes.inputBox} ${classes.borderReset}`}
								value={vipPoint}
								onChange={onVipPointChange}
							/>
						</InputGroup>
					</Col>
					<Col xs="auto">
						<InputGroup>
							<InputGroup.Prepend>
								<InputGroup.Text className={classes.borderReset}>產生數量</InputGroup.Text>
							</InputGroup.Prepend>
							<FormControl
								placeholder="0"
								className={`${classes.inputBox} ${classes.borderReset}`}
								value={quantity}
								onChange={onQuantityChange}
							/>
						</InputGroup>
					</Col>
					<Col xs="auto">
						<Button
							variant="success"
							className={classes.borderReset}
							onClick={onGenerateCode}
							disabled={loading}
						>
							產生
						</Button>
					</Col>
				</Form.Row>
			</Form>
			<Divider className="mt-3 mb-3" />
			<div className="row">
				<div className="col-12">
					<div className="work_area">
						<Table
							columns={columns}
							data={tableData}
							loading={loading}
							tableOption={{
								withSelector: true,
							}}
							events={{
								onActionSelectRows: (v) => console.log(v),
							}}
						/>
					</div>
				</div>
			</div>
			<NormalModal
				show={modifyModalShow}
				title={`修改 ${currentData.date}`}
				onSubmit={onModify}
				onClose={closeModal}
				onExited={clearModalData}
				centered
			>
				<Row>
					<Form.Label column sm={4}>
						發送狀態
					</Form.Label>
					<Col sm={8}>
						<Form.Control
							as="select"
							custom
							value={currentData.sendStatus}
							onChange={onStatusChange}
						>
							<option value={true}>true</option>
							<option value={false}>false</option>
						</Form.Control>
					</Col>
				</Row>
			</NormalModal>
		</Container>
	);
}

import React from "react";
import { ManageTabs } from "../../layout/Tabs/ManageTabs";
import Container from "../../layout/Container";
import { makeStyles, Divider, IconButton } from "@material-ui/core";
import { Form, Col, Row, Button, InputGroup, FormControl } from "react-bootstrap";
import Table from "../../components/common/Table";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import { api_getStaffQRRecord } from "../../API";
import { formateDateToUTC_8 } from "../../utils/date";

const columns = [
	{
		Header: "收款人手機",
		extraClass: "text-center",
		accessor: "userphone",
		disableSortBy: true,
	},
	{
		Header: "類型",
		extraClass: "text-center",
		accessor: "type",
		disableSortBy: true,
	},
	{
		Header: "使用者名稱",
		extraClass: "text-center",
		accessor: "username",
		disableSortBy: true,
	},
	{
		Header: "點數",
		extraClass: "text-center",
		accessor: "point",
		disableSortBy: true,
	},
	{
		Header: "手機號碼",
		extraClass: "text-center",
		accessor: "target",
		disableSortBy: true,
	},
	{
		Header: "掃碼日期",
		extraClass: "text-center",
		accessor: "date",
		disableSortBy: true,
		Cell: ({ value }) => {
			return (
				<div className="d-flex  justify-content-center align-items-center" style={{ gap: '1rem' }}>
					{value === undefined ? '' : formateDateToUTC_8(value)}
				</div>
			)
		},
	},
];

const useStyle = makeStyles((theme) => ({
	borderReset: {
		borderRadius: 2,
	},
	resetButton: {
		border: "1px solid #ced4da",
		borderRadius: 2,
		color: "rgba(0, 0, 0, 0.38)",
		"&:active": {
			border: "1px solid #ced4da",
		},
	},
}));

export function QRCodeRecord(props) {
	const classes = useStyle();
	const [phone, setPhone] = React.useState("");
	const [sourceData, setSourceData] = React.useState([]);
	const [tableData, setTableData] = React.useState([]);
	const [loading, setLoading] = React.useState(true);

	React.useEffect(() => {
		api_getStaffQRRecord()
			.then((res) => {
				console.log(res.data)
				setSourceData(res.data);
				setTableData(res.data);
				setLoading(false);
			})
			.catch((error) => {
				console.log(error);
				alert(error?.response ? error.response.data.msg : error);
			});
	}, []);

	const onPhoneChange = (e) => {
		setPhone(e.target.value);
	};

	const onSearch = () => {
		const queryData = sourceData.filter((i) => i.userphone === phone);
		setTableData(queryData);
	};

	const onReset = () => {
		setTableData(sourceData);
		setPhone("");
	};

	return (
		<Container Tabs={ManageTabs} tabIndex={3}>
			<Form>
				<Form.Row className="align-items-center">
					<Col xs="auto">
						<InputGroup>
							<InputGroup.Prepend>
								<InputGroup.Text className={classes.borderReset}>
									手機
								</InputGroup.Text>
							</InputGroup.Prepend>
							<FormControl
								as="input"
								placeholder="請輸入手機號碼"
								className={`${classes.inputBox} ${classes.borderReset}`}
								value={phone}
								onChange={onPhoneChange}
							/>
						</InputGroup>
					</Col>
					<Col xs="auto">
						<Button variant="info" className={classes.borderReset} onClick={onSearch}>
							查詢帳號
						</Button>
					</Col>
					<Col xs="auto">
						<IconButton className={classes.resetButton} onClick={onReset} size="small">
							<AutorenewIcon />
						</IconButton>
					</Col>
				</Form.Row>
			</Form>
			<Divider className="mt-3 mb-4" />
			<Row>
				<div className="col-12">
					<div className="work_area">
						<Table columns={columns} data={tableData} loading={loading} />
					</div>
				</div>
			</Row>
		</Container>
	);
}

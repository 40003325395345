import React from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "../UserAuthContext";

function PublicRoute({ path, component, redirectTo, exact = false }) {
	const { state } = React.useContext(AuthContext);
	if (state.pass) {
		return <Redirect re={redirectTo} to={redirectTo} />;
	} else {
		return <Route path={path} component={component} exact={exact} />;
	}
}
export default PublicRoute;

import React from "react";
import Container from "../../layout/Container";
import { Divider } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { HomeTabs } from "../../layout/Tabs/HomeTabs";
import Table from "../../components/common/Table";
import { makeStyles } from "@material-ui/core";
import {
	api_getBulletin,
	api_addBulletin,
	api_deleteBulletin,
	api_editAdImg,
	api_editAdLink,
	api_getAdImg,
} from "../../API";
import NormalModal from "../../components/modals/NormalModal";
import { Form, Col, Row, Button, InputGroup, FormControl, ProgressBar } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import { AuthContext } from "../../UserAuthContext";

const useStyle = makeStyles((theme) => ({
	fileLabel: {
		"&:hover": {
			cursor: "pointer",
		},
		"& label::after": {
			content: "attr(data-browse) !important",
		},
	},
}));

export function Bulliet() {
	const [tableData, setTableData] = React.useState([]);
	const [title, setTitle] = React.useState("");
	const [description, setDescription] = React.useState("");
	const [loading, setLoading] = React.useState(true);
	const [currentData, setCurrentData] = React.useState(null);
	const [modifyADModalShow, setModifyADModalShow] = React.useState(false);
	const [uploadPercentage, setUploadPercentage] = React.useState(0);
	const { state } = React.useContext(AuthContext);
	const classes = useStyle();

	const columns = [
		{
			Header: "標題",
			extraClass: "text-center",
			accessor: "title",
			disableSortBy: true,
			width: "30%",
		},
		{
			Header: "內容",
			extraClass: "text-center",
			accessor: "description",
			disableSortBy: true,
			width: "40%",
		},
		{
			Header: "發文時間",
			extraClass: "text-center",
			accessor: "time",
			width: "20%",
		},
		{
			Header: "刪除",
			accessor: (row) => row,
			Cell: ({ row }) => {
				return (
					<div
						className="text-center btn_tool"
						style={{ cursor: "pointer" }}
						onClick={deletePost(row.original)}
					>
						<DeleteIcon />
					</div>
				);
			},
			disableSortBy: true,
			width: "10%",
		},
	];

	React.useEffect(() => {
		reload();
	}, []);

	const reload = () => {
		api_getBulletin()
			.then((res) => {
				setTableData(res.data);
				setLoading(false);
			})
			.catch((error) => {
				alert(error?.response ? error.response.data.msg : error);
				setLoading(false);
			});
	};

	const clearData = () => {
		setLoading("");
		setDescription("");
		setCurrentData(null);
	};

	const onChangeTitle = (e) => {
		setTitle(e.target.value);
	};

	const onChangeDescription = (e) => {
		setDescription(e.target.value);
	};

	const onCurrentDataChange = (key) => {
		return (e) => {
			setCurrentData({
				...currentData,
				[key]: e.target.value,
			});
		};
	};

	const addBulletin = () => {
		const data = { title, description };
		api_addBulletin(data)
			.then((res) => {
				reload();
				clearData();
			})
			.catch((error) => {
				alert(error?.response ? error.response.data.msg : error);
				clearData();
			});
	};

	const resetPost = () => {
		setDescription("");
	};

	const deletePost = (rowData) => {
		const data = { id: rowData.id };
		return () => {
			api_deleteBulletin(data)
				.then((res) => {
					reload();
				})
				.catch((error) => {
					alert(error?.response ? error.response.data.msg : error);
				});
		};
	};

	const onFileChange = (e) => {
		const data = {
			file: e.target.files[0],
			ad_img: URL.createObjectURL(e.target.files[0]),
		};
		console.log(data);
		setCurrentData({
			...currentData,
			...data,
		});
	};

	const onModifyAD = async () => {
		if (currentData.file) {
			const formData = new FormData();
			formData.append("image", currentData?.file);
			api_editAdLink({ link: currentData?.ad_link })
				.then((res) => {
					console.log(res.data);
				})
				.catch((error) => {
					alert(error?.response ? error.response.data.msg : error);
				});
			api_editAdImg(formData, null, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
				onUploadProgress: (progressEvent) => {
					setUploadPercentage(
						parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total))
					);
				},
			})
				.then((res) => {
					closeModal();
					resetData();
					console.log(res);
				})
				.catch((error) => {
					alert(error?.response ? error.response.data.msg : error);
				});
		}
	};

	const showModal = (type, data) => {
		return () => {
			switch (type) {
				case "modifyAD":
					api_getAdImg()
						.then((res) => {
							setCurrentData(res.data);
							console.log(res.data);
							setModifyADModalShow(true);
						})
						.catch((error) => {
							alert(error?.response ? error.response.data.msg : error);
						});
					break;
				default:
					break;
			}
		};
	};

	const closeModal = () => {
		setModifyADModalShow(false);
	};

	const resetData = () => {
		setUploadPercentage(0);
	};

	return (
		<Container Tabs={HomeTabs} tabIndex={2}>
			{state?.user?.type === "admin" && (
				<React.Fragment>
					<div className="row">
						<div className="col align-items-center">
							<Button variant="success" className="float-right" onClick={showModal("modifyAD")}>
								<FontAwesomeIcon icon={faImage} className="mr-2" />
								修改廣告
							</Button>
						</div>
					</div>
					<Divider className="mt-3 mb-3" />
				</React.Fragment>
			)}
			<div className="row mb-3">
				<div className="col">
					<form>
						<div className="form-group">
							<label className="col-form-label">
								<b>標題:</b>
							</label>
							<div>
								<input
									type="text"
									className="form-control"
									placeholder="請輸入標題"
									value={title}
									onChange={onChangeTitle}
								/>
							</div>
						</div>
						<div className="form-group">
							<label className="col-form-label">
								<b>內容:</b>
							</label>
							<div>
								<textarea
									cols="40"
									rows="5"
									className="form-control"
									placeholder="請輸入描述"
									value={description}
									onChange={onChangeDescription}
								/>
							</div>
						</div>
					</form>
					<div className="d-flex justify-content-end">
						<button className="btn btn_common" onClick={resetPost}>
							{/* <i className="float-left icon-add"></i> */}
							<span>重置</span>
						</button>
						<button className="btn btn_common ml-3" onClick={addBulletin}>
							{/* <i className="float-left icon-add"></i> */}
							<span>新增</span>
						</button>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<div className="work_area">
						<Table columns={columns} data={tableData} loading={loading} />
					</div>
				</div>
			</div>
			<NormalModal
				show={modifyADModalShow}
				title={"廣告修改"}
				submitText="上傳並儲存"
				onSubmit={onModifyAD}
				onClose={closeModal}
				onExited={clearData}
				centered
			>
				<Form>
					<Form.Group as={Row}>
						<Form.Label column sm={3}>
							廣告圖片
						</Form.Label>
						<Col sm={9}>
							<Form.File custom className={classes.fileLabel}>
								<Form.File.Input isValid onChange={onFileChange} />
								<Form.File.Label data-browse="選擇照片">{currentData?.file?.name}</Form.File.Label>
								{/* <Form.Control.Feedback type="valid">You did it!</Form.Control.Feedback> */}
							</Form.File>
						</Col>
					</Form.Group>
					<div className="d-flex justify-content-center align-items-center mb-2">
						<img src={currentData?.ad_img} alt="" />
					</div>
					<Form.Group as={Row}>
						<Form.Label column sm={3}>
							廣告網址
						</Form.Label>
						<Col sm={9}>
							<FormControl
								type="text"
								placeholder="請輸入完整網址"
								value={currentData?.ad_link || ""}
								onChange={onCurrentDataChange("ad_link")}
							/>
						</Col>
					</Form.Group>
					<ProgressBar now={uploadPercentage} label={`${uploadPercentage}%`} />
				</Form>
			</NormalModal>
		</Container>
	);
}

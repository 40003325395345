export const formateDateToUTC_8 = (dateString) => {
  try {

    const [date, time] = dateString.split(' ')
    const dateArray = date.split(/[/-]/)
    //month  - 1
    dateArray[1] = dateArray[1] - 1
    const timeArray = time.split(':')
    const utcString = dateArray.concat(timeArray)
    const UTCTime = new Date(Date.UTC(...utcString))
    const UTC_8_Time = new Intl.DateTimeFormat('zh-ch', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    }).format(UTCTime)
    return UTC_8_Time
  } catch (e) {
    console.log(e)
  }
}

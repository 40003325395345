import React, { Fragment } from "react";
import {
	useTable,
	useSortBy,
	useFilters,
	useExpanded,
	usePagination,
	useFlexLayout,
	useBlockLayout,
	useRowSelect,
} from "react-table";
// import { useSticky } from "react-table-sticky";
import { Button } from "react-bootstrap";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
// import "../../scss/components/component.scss";

const defaultTableOptions = {
	pageSize: null,
	editorHtml: null,
	tfootHtml: null,
	withFilter: null,
	withSelector: null,
	hiddenPagination: null,
};

const defaultEvents = {
	onMouseOver: null,
	onMouseOut: null,
	onClick: null,
};

function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
	const count = preFilteredRows.length;
	return "";
}

const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
	const defaultRef = React.useRef();
	const resolvedRef = ref || defaultRef;

	React.useEffect(() => {
		resolvedRef.current.indeterminate = indeterminate;
	}, [resolvedRef, indeterminate]);

	return <input type="checkbox" ref={resolvedRef} {...rest} />;
});

const useStyles = makeStyles((theme) => ({
	sort_area: {
		display: "inline-flex",
		"& svg": {
			fontSize: "20px",
		},
	},
	sort_down_icon: {
		position: "absolute",
		top: "10px",
	},
	sort_asc_icon: {
		position: "relative",
		top: "4px",
		fontSize: "20px",
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: "#252b4a",
		position: "absolute",
		background: "none",
	},
	inputContainer: {
		display: "flex",
		alignItems: "center",
		fontSize: "16px",
		lineHeight: "16px",
		color: "#646464",
		padding: "0 10px",
		border: "1px solid #ced4da",
		borderLeft: "none",
		"& input": {
			border: "none",
			background: "#F2F2F2",
			width: "50px",
			margin: "0 10px",
			color: "#646464",
			textAlign: "center",
		},
	},
}));

export default function Table({
	columns,
	data,
	tableOption = defaultTableOptions,
	events = defaultEvents,
	loading = false,
	renderRowSubComponent,
}) {
	const classes = useStyles();
	const defaultColumn = React.useMemo(
		() => ({
			// Let's set up our default Filter UI
			Filter: DefaultColumnFilter,
		}),
		[]
	);
	const {
		getTableProps,
		getTableBodyProps,
		prepareRow,
		headerGroups,
		rows,
		visibleColumns,
		page,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		selectedFlatRows,
		state: { pageIndex, pageSize },
	} = useTable(
		{
			columns,
			data,
			defaultColumn,
			initialState: {
				pageIndex: 0,
				pageSize: tableOption?.pageSize || 10,
			},
		},
		useFilters,
		useSortBy,
		useExpanded,
		usePagination,
		useRowSelect,
		(hooks) => {
			if (tableOption?.withSelector) {
				hooks.visibleColumns.push((columns) => [
					// Let's make a column for selection
					{
						id: "selection",
						// The header can use the table's getToggleAllRowsSelectedProps method
						// to render a checkbox
						Header: ({ getToggleAllPageRowsSelectedProps }) => (
							<div className="text-center">
								<IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
							</div>
						),
						// The cell can use the individual row's getToggleRowSelectedProps method
						// to the render a checkbox
						Cell: ({ row }) => (
							<div className="d-flex justify-content-center align-items-center">
								<IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
							</div>
						),
						width: "50px",
					},
					...columns,
				]);
			}
		}
		// useFlexLayout,
		// useSticky
	);
	let editor = tableOption.editorHtml;
	let tfoot = tableOption.tfootHtml;
	let withFilter = tableOption.withFilter;
	let withSelector = tableOption.withSelector;
	let hiddenPagination = tableOption.hiddenPagination;
	let onMouseOver = events.onMouseOver;
	let onMouseOut = events.onMouseOut;
	let onClick = events.onClick;

	return (
		<React.Fragment>
			<table {...getTableProps()}
				className="table mb-0 table-striped"
				style={{
					minWidth: "100vw",
				}}
			>
				<thead className="header">
					{headerGroups.map((headerGroup, groupIndex) => (
						<React.Fragment key={groupIndex}>
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column, index) => {
									const needSticky = ['修改', '刪除'].includes(column.Header)

									// sticky_field_edit
									// sticky_field_delete
									return (
										// Add the sorting props to control sorting. For this example
										// we can add them into the header props
										<th
											key={index}
											{...column.getHeaderProps(
												column.getSortByToggleProps()
											)}
											className={clsx(
												"th_style ",
												index === 0 ? "" : "text-center ",
												column.extraClass ? column.extraClass : "",
												(needSticky ?
													column.Header === '修改' ?
														'sticky_field_edit' :
														'sticky_field_delete'
													: ''
												)
											)}
											width={column.width}
										>
											{/* Add a sort direction indicator */}
											{column.render("Header")}
											{
												column.isSorted ? (
													column.isSortedDesc ? (
														<span className={classes.sort_area}>
															<ArrowDropUpIcon />
														</span>
													) : (
														// ? <span className="th_sort sort_desc"></span>
														<span>
															<ArrowDropDownIcon
																className={classes.sort_asc_icon}
															/>
														</span>
													)
												) : // : <span className="th_sort sort_asc"></span>
													column.canSort ? (
														<span className={classes.sort_area}>
															<ArrowDropUpIcon />
															<ArrowDropDownIcon
																className={classes.sort_down_icon}
															/>
														</span>
													) : (
														<span className="th_style"></span>
													)
												//  <span className={(column.canSort) ? "th_sort" : "th_style"}></span>
											}
										</th>
									);
								})}
								{editor ? tableOption.editorHtml[0] : null}
							</tr>
							{withSelector && selectedFlatRows.length > 0 ? (
								<tr>
									{headerGroup.headers.map((column, index) => (
										<td
											key={index}
											width={column.width}
											className="text-center"
										>
											{index === 0 && "操作"}
											{column.canAction ? column.render("Action") : null}
										</td>
									))}
								</tr>
							) : null}
							{withFilter ? (
								<tr>
									{headerGroup.headers.map((column, index) => (
										<td
											key={index}
											className={
												"pes_input " + (index === 0 ? "" : "text-center")
											}
											width={column.width}
										>
											{column.canFilter ? column.render("Filter") : null}
										</td>
									))}
								</tr>
							) : null}
						</React.Fragment>
					))}
				</thead>
				{tfoot ? tableOption.tfootHtml[0] : null}
				<tbody {...getTableBodyProps()} className="body">
					{page.map((row, i) => {
						prepareRow(row);
						return (
							<Fragment key={i}>
								<tr
									{...row.getRowProps()}
									onMouseOver={
										onMouseOver ? (e) => onMouseOver(row, i) : () => { }
									}
									onMouseOut={onMouseOut ? (e) => onMouseOut(row, i) : () => { }}
									onClick={onClick ? (e) => onClick(row, i) : () => { }}
								>
									{row.cells.map((cell, index) => {
										const needSticky = ['修改', '刪除'].includes(cell.column.Header)

										// sticky_field_edit
										// sticky_field_delete
										return (
											<td
												key={index}
												{...cell.getCellProps()}
												className={
													(index === 0 ? "" : "text-center ") +
													(cell.column.extraClass
														? cell.column.extraClass
														: "") +
													(needSticky ?
														cell.column.Header === '修改' ?
															'sticky_field_edit' :
															'sticky_field_delete'
														: ''
													)
												}
												style={cell.column.extraStyle || null}
											>
												{row.isExpanded
													? cell.column.showOnExpanded
														? cell.render("Cell")
														: null
													: cell.render("Cell")}
											</td>
										);
									})}
									{editor ? tableOption.editorHtml[1] : null}
								</tr>
								{renderRowSubComponent ? (
									row.isExpanded ? (
										<tr>
											<td colSpan={visibleColumns.length}>
												{renderRowSubComponent({ row })}
											</td>
										</tr>
									) : null
								) : null}
							</Fragment>
						);
					})}
					{rows.length === 0 ? (
						<tr>
							<td
								colSpan={withSelector ? columns.length + 1 : columns.length}
								className="text-center"
							>
								No data available in table
							</td>
						</tr>
					) : null}
				</tbody>
			</table>
			<div className="d-flex justify-content-xl-end justify-content-md-center">
				<nav className="pagination_area clearfix">
					<ul className="pagination mb-0">
						<li className="page-item">
							<button
								hidden={hiddenPagination}
								id="previousPage"
								className={clsx("page-link", !canPreviousPage && "disable")}
								onClick={() => previousPage()}
								disabled={!canPreviousPage}
							>
								上一頁
							</button>
						</li>
						<li className="page-item">
							<span hidden={hiddenPagination} className="page-link">
								第
								{` ${pageIndex + 1}/${pageOptions.length === 0 ? 1 : pageOptions.length
									} `}
								頁
							</span>
						</li>
						<li className="page-item">
							<button
								hidden={hiddenPagination}
								id="nextPage"
								className={clsx("page-link", !canNextPage && "disable")}
								onClick={() => nextPage()}
								disabled={!canNextPage}
							>
								下一頁
							</button>
						</li>
						<div className={classes.inputContainer}>
							到第
							<input
								hidden={false}
								defaultValue={pageIndex + 1}
								onChange={(e) => {
									const page = e.target.value ? Number(e.target.value) - 1 : 0;
									gotoPage(page);
								}}
							/>
							頁
						</div>
					</ul>
				</nav>
			</div>
			<Backdrop className={classes.backdrop} open={loading} onClick={() => { }}>
				<CircularProgress color="inherit" />
			</Backdrop>
		</React.Fragment>
	);
}

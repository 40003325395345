import React from "react";
import { HomeTabs } from "../../layout/Tabs/HomeTabs";
import Container from "../../layout/Container";
import { Form } from "react-bootstrap";
import { api_getWorkRule, api_editWorkRule } from "../../API";

// const types = [
// 	{ id: "deposit", name: "儲值頁" },
// 	{ id: "recv", name: "收費頁" },
// 	{ id: "employee_transfer", name: "員工間轉點" },
// 	{ id: "consumer_transfer", name: "消費者間轉點" },
// 	{ id: "vip_rule_customer", name: "VIP制度-會員" },
// 	{ id: "vip_rule_employee", name: "VIP制度-員工" },
// 	{ id: "dct_website", name: "DCT官方網站" },
// 	{ id: "website", name: "網址" },
// ];

export function WorkUse() {
	const [workUserList, setWorkUserList] = React.useState([]);
	const [selectedType, setSelectedType] = React.useState(0);
	const [description, setDescription] = React.useState("");

	React.useEffect(() => {
		reload();
	}, []);

	const reload = () => {
		api_getWorkRule()
			.then((res) => {
				setWorkUserList(res.data);
				if (res.data.length > 1) {
					setDescription(res.data[0].contents);
				}
			})
			.catch((error) => {
				alert(error?.response ? error.response.data.msg : error);
			});
	};

	const onSelect = (e) => {
		setSelectedType(e.target.value);
		setDescription(workUserList[e.target.value].contents);
	};

	const onChange = (e) => {
		setDescription(e.target.value);
	};

	const modifyRule = () => {
		const data = { id: workUserList[selectedType].id, contents: description };
		api_editWorkRule(data)
			.then((res) => {
				alert("修改成功");
			})
			.catch((error) => {
				alert(error?.response ? error.response.data.msg : error);
			});
	};

	const clearRule = () => {
		setDescription("");
	};

	return (
		<Container Tabs={HomeTabs} tabIndex={3}>
			<div className="row col">
				<Form>
					<Form.Group>
						<Form.Label>
							<b>規範分頁選擇:</b>
						</Form.Label>
						<Form.Control
							as="select"
							custom
							style={{ borderRadius: 0 }}
							onChange={onSelect}
							value={selectedType}
						>
							{workUserList.map((item, i) => {
								return (
									<option key={i} value={i}>
										{item.type}
									</option>
								);
							})}
						</Form.Control>
					</Form.Group>
				</Form>
			</div>
			<div className="row">
				<div className="col">
					<form>
						<div className="form-group">
							<label className="col-form-label">
								<b>內容:</b>
							</label>
							<div>
								<textarea
									cols="40"
									rows="5"
									className="form-control"
									placeholder="請輸入描述"
									value={description}
									onChange={onChange}
								/>
							</div>
						</div>
					</form>
					<div className="d-flex justify-content-end">
						<button className="btn btn_common" onClick={clearRule}>
							{/* <i className="float-left icon-add"></i> */}
							<span>重置</span>
						</button>
						<button className="btn btn_common ml-3" onClick={modifyRule}>
							{/* <i className="float-left icon-add"></i> */}
							<span>修改</span>
						</button>
					</div>
				</div>
			</div>
		</Container>
	);
}

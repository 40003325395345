import React from "react";
import Tabs from "../Tabs";
import { protectedRoutes } from "../../routes/Routes";
import { AuthContext } from "../../UserAuthContext";
import { permissionRoles } from "../../roles";

export function ManageTabs({ index }) {
	const { state } = React.useContext(AuthContext);
	const tabData = protectedRoutes.filter((route) => {
		const pathArray = route.path.split("/");
		return (
			pathArray.length === 3 &&
			pathArray[1] === "manage" &&
			route.roles.some((i) => permissionRoles[i] === state.user.type)
		);
	});


	return <Tabs tabData={tabData} currIndex={index} />;
}

import { Login } from "../pages/login";
import { Console, Code, Bulliet, WorkUse, TradeRule, Deposit } from "../pages/home";
import {
	DepositRecord,
	Consumption,
	TransferRecord as ConsumerTransferRecord,
	QRCodeRecord,
	RefundRecord,
} from "../pages/consumer";
import {
	TransferRecord as ManageTransferRecord,
	SalaryRecord,
	QRCodeRecord as ManageQRCodeRecord,
} from "../pages/manage";
import { UserConsumer, UserEmployee, UserCompany, UserAccount } from "../pages/user";

export const publicRoutes = [
	{ path: "/", component: Login, exact: true },
	{ path: "/login", component: Login },
];

export const protectedRoutes = [
	{ path: "/home", component: Console, exact: true, roles: [1, 2] },
	{ path: "/home", component: Code, exact: true, roles: [3, 4] },
	{ path: "/home/console", component: Console, tabName: "控制台", tabIndex: 1, roles: [1, 2] },
	{ path: "/home/bulliet", component: Bulliet, tabName: "布告欄", tabIndex: 2, roles: [1, 2] },
	{ path: "/home/workUse", component: WorkUse, tabName: "使用規範", tabIndex: 3, roles: [1, 2] },
	{ path: "/home/code", component: Code, tabName: "序號管理", tabIndex: 4, roles: [1, 2, 3, 4] },
	{ path: "/home/tradeRule", component: TradeRule, tabName: "交易規則", tabIndex: 5, roles: [1] },
	{ path: "/home/deposit", component: Deposit, tabName: "儲值金額", tabIndex: 6, roles: [1] },
	{ path: "/consumer", component: DepositRecord, exact: true, roles: [1, 2, 3] },
	{
		path: "/consumer/depositRecord",
		component: DepositRecord,
		tabName: "儲值紀錄",
		tabIndex: 1,
		roles: [1, 2, 3],
	},
	{
		path: "/consumer/consumption",
		component: Consumption,
		tabName: "消費紀錄",
		tabIndex: 2,
		roles: [1, 2, 3],
	},
	{
		path: "/consumer/refundRecord",
		component: RefundRecord,
		tabName: "退款紀錄",
		tabIndex: 3,
		roles: [1, 2, 3],
	},
	{
		path: "/consumer/transferRecord",
		component: ConsumerTransferRecord,
		tabName: "轉帳紀錄",
		tabIndex: 4,
		roles: [1, 2],
	},
	{
		path: "/consumer/QRCodeRecord",
		component: QRCodeRecord,
		tabName: "QRC紀錄",
		tabIndex: 5,
		roles: [1, 2, 3],
	},
	{ path: "/manage", component: SalaryRecord, exact: true, roles: [1, 2] },
	{
		path: "/manage/salaryRecord",
		component: SalaryRecord,
		tabName: "領薪紀錄",
		tabIndex: 1,
		roles: [1, 2],
	},
	{
		path: "/manage/transferRecord",
		component: ManageTransferRecord,
		tabName: "員工轉賬記錄",
		tabIndex: 2,
		roles: [1, 2],
	},
	{
		path: "/manage/QRCodeRecord",
		component: ManageQRCodeRecord,
		tabName: "QRC紀錄",
		tabIndex: 3,
		roles: [1, 2, 3],
	},
	{ path: "/users", component: UserConsumer, exact: true, roles: [1, 2, 3, 4] },
	{
		path: "/users/userConsumer",
		component: UserConsumer,
		tabName: "消費者清單",
		tabIndex: 1,
		roles: [1, 2, 3, 4],
	},
	{
		path: "/users/userCompany",
		component: UserCompany,
		tabName: "廠商清單",
		tabIndex: 2,
		roles: [1, 2],
	},
	{
		path: "/users/userEmployee",
		component: UserEmployee,
		tabName: "員工清單",
		tabIndex: 3,
		roles: [1, 2],
	},
	{
		path: "/users/userAccount",
		component: UserAccount,
		tabName: "後台帳戶",
		tabIndex: 4,
		roles: [1, 2],
	},
];

import React from "react";
import { UserTabs } from "../../layout/Tabs/UserTabs";
import Container from "../../layout/Container";
import { makeStyles, Divider, IconButton } from "@material-ui/core";
import { Form, Col, Row, Button, InputGroup, FormControl } from "react-bootstrap";
import Table from "../../components/common/Table";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import CancelIcon from "@material-ui/icons/Cancel";
import { api_getAccount, api_addAccount, api_delAccount } from "../../API";
import moment from "moment";
import NormalModal from "../../components/modals/NormalModal";
import { AuthContext } from "../../UserAuthContext";

const roleList = [
	{ id: "admin", name: "最高權限管理員" },
	{ id: "manager", name: "管理員" },
	{ id: "editor", name: "編輯者" },
	{ id: "viewer", name: "檢視者" },
];

const useStyle = makeStyles((theme) => ({
	borderReset: {
		borderRadius: 2,
	},
	resetButton: {
		border: "1px solid #ced4da",
		borderRadius: 2,
		color: "rgba(0, 0, 0, 0.38)",
		"&:active": {
			border: "1px solid #ced4da",
		},
	},
}));

export function UserAccount(props) {
	const classes = useStyle();
	const [account, setAccount] = React.useState("");
	const [sourceData, setSourceData] = React.useState([]);
	const [tableData, setTableData] = React.useState([]);
	const [loading, setLoading] = React.useState(true);
	const [currentData, setCurrentData] = React.useState({});
	const [addModalShow, setAddModalShow] = React.useState(false);
	const [deleteModalShow, setDeleteModalShow] = React.useState(false);
	const { state } = React.useContext(AuthContext);

	const normalColumns = [
		{
			Header: "帳號",
			extraClass: "text-center",
			accessor: "account",
			disableSortBy: true,
		},
		{
			Header: "身分",
			extraClass: "text-center",
			accessor: (row) => {
				switch (row.type) {
					case "admin":
						return "最高權限管理員";
					case "manager":
						return "管理員";
					case "editor":
						return "編輯者";
					case "viewer":
						return "檢視者";
					default:
						return "";
				}
			},
			disableSortBy: true,
		},
	];

	const adminColumns = [
		...normalColumns,
		{
			Header: "刪除",
			accessor: (row) => row,
			Cell: ({ row }) => {
				return (
					<div
						className="text-center btn_tool"
						style={{ cursor: "pointer" }}
						onClick={showModal("delete", row.original)}
					>
						<DeleteIcon />
					</div>
				);
			},
			disableSortBy: true,
		},
	];

	const columns = state?.user?.type === "admin" ? adminColumns : normalColumns;

	React.useEffect(() => {
		reload();
	}, []);

	const reload = () => {
		api_getAccount()
			.then((res) => {
				setSourceData(res.data);
				setTableData(res.data);
				setLoading(false);
			})
			.catch((error) => {
				alert(error?.response ? error.response.data.msg : error);
			});
	};

	const onAccountChange = (e) => {
		setAccount(e.target.value);
	};

	const onSearch = () => {
		const queryData = tableData.filter((i) => i.account === account);
		setTableData(queryData);
	};

	const onReset = () => {
		setTableData(sourceData);
		setAccount("");
	};

	const onAddAccount = () => {
		const data = {
			account: currentData.account,
			password: currentData.password,
			type: roleList[currentData.roleIndex].id,
		};
		api_addAccount(data)
			.then((res) => {
				closeModal();
				reload();
			})
			.catch((error) => {
				alert(error?.response ? error.response.data.msg : error);
			});
	};

	const onSelectRole = (e) => {
		setCurrentData({
			...currentData,
			roleIndex: e.target.value,
		});
	};

	const showModal = (type, data) => {
		return () => {
			switch (type) {
				case "add":
					setAddModalShow(true);
					setCurrentData({ roleIndex: 0 });
					break;
				case "delete":
					setCurrentData(data);
					setDeleteModalShow(true);
					break;
				default:
					break;
			}
		};
	};

	const closeModal = () => {
		setAddModalShow(false);
		setDeleteModalShow(false);
	};

	const onCurrentDataChange = (key) => {
		return (e) => {
			setCurrentData({
				...currentData,
				[key]: e.target.value,
			});
		};
	};

	const clearData = () => {
		setCurrentData({});
	};

	const onDeleteAccount = () => {
		const data = {
			account: currentData.account,
		};
		api_delAccount(data)
			.then((res) => {
				reload();
				closeModal();
			})
			.catch((error) => {
				alert(error?.response ? error.response.data.msg : error);
			});
	};

	return (
		<Container Tabs={UserTabs} tabIndex={4}>
			<Form>
				<Form.Row className="align-items-center">
					<Col xs="auto">
						<InputGroup>
							<InputGroup.Prepend>
								<InputGroup.Text className={classes.borderReset}>後台帳號</InputGroup.Text>
							</InputGroup.Prepend>
							<FormControl
								placeholder="請輸入帳號"
								className={`${classes.inputBox} ${classes.borderReset}`}
								value={account}
								onChange={onAccountChange}
							/>
						</InputGroup>
					</Col>
					<Col xs="auto">
						<Button variant="info" className={classes.borderReset} onClick={onSearch}>
							查詢帳號
						</Button>
					</Col>
					<Col xs="auto">
						<IconButton className={classes.resetButton} onClick={onReset} size="small">
							<AutorenewIcon />
						</IconButton>
					</Col>
					<Col xs="auto" className="ml-auto">
						<Button variant="primary" className={classes.borderReset} onClick={showModal("add")}>
							新增後台帳戶
						</Button>
					</Col>
				</Form.Row>
			</Form>
			<Divider className="mt-3 mb-4" />
			<Row>
				<div className="col-12">
					<div className="work_area">
						<Table columns={columns} data={tableData} loading={loading} />
					</div>
				</div>
			</Row>
			<NormalModal
				show={deleteModalShow}
				title={`刪除後台帳戶`}
				onSubmit={onDeleteAccount}
				onClose={closeModal}
				onExited={clearData}
				centered
			>
				<p>
					{"確定刪除此後台帳戶"}
					<b className="ml-2">{currentData?.account}</b>
				</p>
			</NormalModal>
			<NormalModal
				show={addModalShow}
				title={"新建後台帳戶"}
				onSubmit={onAddAccount}
				onClose={closeModal}
				onExited={clearData}
				centered
			>
				<Form>
					<Form.Group as={Row}>
						<Form.Label column sm={3}>
							帳號
						</Form.Label>
						<Col sm={9}>
							<FormControl
								placeholder="請輸入帳號"
								value={currentData?.account || ""}
								onChange={onCurrentDataChange("account")}
							/>
						</Col>
					</Form.Group>
					<Form.Group as={Row}>
						<Form.Label column sm={3}>
							密碼
						</Form.Label>
						<Col sm={9}>
							<FormControl
								type="password"
								placeholder="請輸入密碼"
								value={currentData?.password || ""}
								onChange={onCurrentDataChange("password")}
							/>
						</Col>
					</Form.Group>
					<Form.Group as={Row}>
						<Form.Label column sm={3}>
							角色
						</Form.Label>
						<Col sm={9}>
							<FormControl
								as="select"
								custom
								style={{ borderRadius: 0 }}
								onChange={onSelectRole}
								value={currentData?.roleIndex}
							>
								{roleList.map((item, i) => {
									return (
										<option key={i} value={i}>
											{item.name}
										</option>
									);
								})}
							</FormControl>
						</Col>
					</Form.Group>
				</Form>
			</NormalModal>
		</Container>
	);
}

import React from "react";
import Container from "../../layout/Container";
import Table from "../../components/common/Table";
import CreateIcon from "@material-ui/icons/Create";
import { Form, Row, Col, FormControl } from "react-bootstrap";
import { HomeTabs } from "../../layout/Tabs/HomeTabs";
import { api_getTradeRules, api_editDepositRule } from "../../API";
import NormalModal from "../../components/modals/NormalModal";

export function Deposit() {
	const [loading, setLoading] = React.useState(true);
	const [tableData, setTableData] = React.useState([]);
	const [currentData, setCurrentData] = React.useState({});
	const [modifyModalShow, setModifyModalShow] = React.useState(false);

	const columns = [
		{
			Header: "規則",
			extraClass: "text-center",
			accessor: "title",
			disableSortBy: true,
		},
		{
			Header: "鑽石",
			extraClass: "text-center",
			accessor: "diamond",
		},
		{
			Header: "回饋點數",
			extraClass: "text-center",
			accessor: "gift",
		},
		{
			Header: "修改",
			accessor: (row) => row,
			Cell: ({ row }) => {
				return (
					<div
						className="text-center btn_tool"
						style={{ cursor: "pointer" }}
						onClick={onShowModifyModal(row.original)}
					>
						<CreateIcon />
					</div>
				);
			},
			disableSortBy: true,
		},
	];

	React.useEffect(() => {
		reload();
	}, []);

	const reload = () => {
		api_getTradeRules()
			.then((res) => {
				const table_value = [];
				const arr = ["rule1", "rule2", "rule3", "rule4"];

				for (var i = 0; i <= 3; i++) {
					let key = arr[i];
					table_value.push({
						title: key,
						title: "rule" + (i + 1),
						diamond: res.data[8][key]["diamond"],
						gift: res.data[8][key]["gift"],
					});
				}
				setTableData(table_value);
				setLoading(false);
			})
			.catch((error) => {
				alert(error?.response ? error.response.data.msg : error);
			});
	};

	const onModify = () => {
		const data = {
			title: currentData.title,
			diamond: parseInt(currentData.diamond),
			gift: parseInt(currentData.gift),
		};
		api_editDepositRule(data)
			.then((res) => {
				reload();
				closeModal();
			})
			.catch((error) => {
				alert(error?.response ? error.response.data.msg : error);
			});
	};

	const onModifyDataChange = (key) => {
		return (e) => {
			setCurrentData({
				...currentData,
				[key]: e.target.value,
			});
		};
	};

	const onShowModifyModal = (data) => {
		return () => {
			setCurrentData(data);
			setModifyModalShow(true);
		};
	};

	const closeModal = () => {
		setModifyModalShow(false);
	};

	const clearModalData = () => {
		setCurrentData({});
	};

	return (
		<Container Tabs={HomeTabs} tabIndex={6}>
			<div className="row">
				<div className="col-12">
					<div className="work_area">
						<Table columns={columns} data={tableData} loading={loading} />
					</div>
				</div>
			</div>
			<NormalModal
				show={modifyModalShow}
				title={`修改 ${currentData.title}`}
				onSubmit={onModify}
				onClose={closeModal}
				onExited={clearModalData}
				centered
			>
				<Form.Group as={Row}>
					<Form.Label column sm={4}>
						鑽石
					</Form.Label>
					<Col sm={8}>
						<FormControl
							placeholder="0"
							value={currentData.diamond}
							onChange={onModifyDataChange("diamond")}
						/>
					</Col>
				</Form.Group>
				<Form.Group as={Row}>
					<Form.Label column sm={4}>
						回饋點數
					</Form.Label>
					<Col sm={8}>
						<FormControl
							placeholder="0"
							value={currentData.gift}
							onChange={onModifyDataChange("gift")}
						/>
					</Col>
				</Form.Group>
			</NormalModal>
		</Container>
	);
}

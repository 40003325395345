import axios from "axios";

const host_serverAddress = process.env.REACT_APP_BASE_API || 'https://api.dct-back-end.com';

// * api種類
const request = axios.create({
	baseURL: host_serverAddress + "/",
});
const homePage_request = axios.create({
	baseURL: host_serverAddress + "/homePage",
});
const customer_request = axios.create({
	baseURL: host_serverAddress + "/customer",
});
const employee_request = axios.create({
	baseURL: host_serverAddress + "/employee",
});
const user_request = axios.create({
	baseURL: host_serverAddress + "/users",
});

// axios request hook
const setHeader = (config) => {
	const token = localStorage.getItem("dct-token");
	config.headers.Authorization = token;
	return config;
};
request.interceptors.request.use(setHeader);
homePage_request.interceptors.request.use(setHeader);
customer_request.interceptors.request.use(setHeader);
employee_request.interceptors.request.use(setHeader);
user_request.interceptors.request.use(setHeader);

// axios response hook
const setResponse = (res) => {
	if (res.status === 401) {
		localStorage.setItem("dct-token", "");
		// window.location.href = "/";
		window.location.reload();
	}
	return res;
};
request.interceptors.response.use(setResponse);
homePage_request.interceptors.response.use(setResponse);
customer_request.interceptors.response.use(setResponse);
employee_request.interceptors.response.use(setResponse);
user_request.interceptors.response.use(setResponse);

// * account
export const api_login = (data) => request.post("login", data);
export const api_token = (data) => request.post("token", data);
export const api_addAccount = (data) => request.post("addAccount", data);
export const api_getAccount = () => request.get("getAccount");
export const api_delAccount = (data) => request.delete("delAccount", { data });

// * homepage
export const api_getRechargeTotal = () => homePage_request.get("getRechargeTotal");
export const api_getEmployeeTransferTotal = () => homePage_request.get("getEmployeeTransferTotal");
export const api_getTransactionTotal = () => homePage_request.get("getTransactionTotal");
export const api_getSalaryTotal = () => homePage_request.get("getSalaryTotal");
export const api_getCustomerTransferTotal = () => homePage_request.get("getCustomerTransferTotal");
export const api_getCashflowTotal = () => homePage_request.get("getCashflowTotal");
export const api_getRevenueTotal = () => homePage_request.get("getRevenueTotal");
export const api_getCostTotal = () => homePage_request.get("getCostTotal");
export const api_getAllMonthRecharge = () => homePage_request.get("getAllMonthRecharge");
export const api_getBulletin = () => homePage_request.get("getBulletin");
export const api_getWorkRule = (params) => homePage_request.get("getWorkRule", { params });
export const api_getCode = () => homePage_request.get("getCode");
export const api_getTradeRules = () => homePage_request.get("getTradeRules");
export const api_addBulletin = (data, params) =>
	homePage_request.post("addBulletin", data, { params });
export const api_deleteBulletin = (data, params) =>
	homePage_request.delete("deleteBulletin", { params, data });
export const api_editWorkRule = (data, params) =>
	homePage_request.post("editWorkRule", data, { params });
export const api_addCode = (data, params) => homePage_request.post("addCode", data, { params });
export const api_editCodeSendStatus = (data, params) =>
	homePage_request.post("editCodeSendStatus", data, { params });
export const api_deleteCode = (data, params) =>
	homePage_request.delete("deleteCode", { params, data });
export const api_editTradeRule = (data, params) =>
	homePage_request.post("/editTradeRule", data, { params });
export const api_editDepositRule = (data, params) =>
	homePage_request.post("/editDepositRule", data, { params });

// * customer
export const api_getAllDepositRecord = () => customer_request.get("getAllDepositRecord");
export const api_getAllEmployeeChargeRecord = () =>
	customer_request.get("getAllEmployeeChargeRecord");
export const api_getCustomerQRRecord = () => customer_request.get("getCustomerQRRecord");
export const api_getAllUsersUserChargeRecord = () =>
	customer_request.get("getAllUsersUserChargeRecord");
export const api_getRefundRecord = () => customer_request.get("getRefundRecord");
export const api_rechargeRefund = (data) => customer_request.post("rechargeRefund", data);
export const api_transactionRefund = (data) => customer_request.post("transactionRefund", data);
export const api_addEmployeeChargeRecord = (data) =>
	customer_request.post("addEmployeeChargeRecord", data);

// * employee
export const api_getAllEmployees = () => employee_request.get("getAllEmployees");
export const api_getSalaryRecord = () => employee_request.get("getSalaryRecord");
export const api_writeOff = (data, params) => employee_request.post("writeOff", data, { params });
export const api_getAllEmployeeRecord = () => employee_request.get("getAllEmployeeRecord");
export const api_getStaffQRRecord = () => employee_request.get("getStaffQRRecord");
export const api_addEmployee = (data) => employee_request.post("addEmployee", data);
export const api_deleteEmployee = (data) => employee_request.post("deleteEmployee", data);
export const api_editPoint = (data) => employee_request.post("editPoint", data);
export const api_getAdImg = () => employee_request.get("getAdImg");
export const api_editAdImg = (data, params, options) =>
	employee_request.post("editAdImg", data, { params, ...options });
export const api_editAdLink = (data) => employee_request.post("editAdLink", data);
export const api_getAppInfo = () => employee_request.get("getAllEmployees");

// * user
export const api_getAllUsers = () => user_request.get("getAllUsers");
export const api_getAllCompany = () => user_request.get("getAllCompany");
export const api_editComputer = (data) => user_request.post("editComputer", data);
export const api_deleteUser = (data) => user_request.post("deleteUser", data);
export const api_editToCompany = (data) => user_request.post("editToCompany", data);
export const api_editRemarks = (data) => user_request.post("editRemarks", data);
export const api_editUserPoint = (data) => user_request.post("editUserPoint", data);
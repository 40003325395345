import React from "react";
import { ConsumerTabs } from "../../layout/Tabs/ConsumerTabs";
import Container from "../../layout/Container";
import { makeStyles, Divider, IconButton } from "@material-ui/core";
import { Form, Col, Row, Button, InputGroup, FormControl } from "react-bootstrap";
import Table from "../../components/common/Table";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import { api_getAllDepositRecord, api_rechargeRefund } from "../../API";
import { AuthContext } from "../../UserAuthContext";
import NormalModal from "../../components/modals/NormalModal";
import { formateDateToUTC_8 } from "../../utils/date";

const useStyle = makeStyles((theme) => ({
	borderReset: {
		borderRadius: 2,
	},
	resetButton: {
		border: "1px solid #ced4da",
		borderRadius: 2,
		color: "rgba(0, 0, 0, 0.38)",
		"&:active": {
			border: "1px solid #ced4da",
		},
	},
}));

export function DepositRecord(props) {
	const classes = useStyle();
	const [phone, setPhone] = React.useState("");
	const [sourceData, setSourceData] = React.useState([]);
	const [tableData, setTableData] = React.useState([]);
	const [loading, setLoading] = React.useState(true);
	const [currentData, setCurrentData] = React.useState(null);
	const [refundModalShow, setRefundModalShow] = React.useState(false);
	const { state } = React.useContext(AuthContext);
	console.log(tableData[0])
	const normalColumns = [
		{
			Header: "帳號",
			extraClass: "text-center",
			accessor: "FBname",
			disableSortBy: true,
		},
		{
			Header: "手機",
			extraClass: "text-center",
			accessor: "phone",
			disableSortBy: true,
		},
		{
			Header: "儲值日期",
			extraClass: "text-center",
			accessor: "PaymentDate",
			Cell: ({ value }) => {
				return (
					<div className="d-flex  justify-content-center align-items-center " style={{ gap: '1rem' }}>
						{value === undefined ? '' : formateDateToUTC_8(value)}
					</div>
				)
			},
			disableSortBy: true,
		},
		{
			Header: "鑽石",
			extraClass: "text-center",
			accessor: "Diamond",
			disableSortBy: true,
		},
		{
			Header: "交易金額",
			extraClass: "text-center",
			accessor: "TradeAmount",
			disableSortBy: true,
		},
		{
			Header: "回饋點數",
			extraClass: "text-center",
			accessor: "Bonus",
			disableSortBy: true,
		},
		{
			Header: "退款狀態",
			extraClass: "text-center",
			accessor: (row) => (row.refund_status ? "已退款" : ""),
			disableSortBy: true,
		},
	];

	const adminColumns = [
		...normalColumns,
		{
			Header: "退款",
			accessor: (row) => row,
			Cell: ({ row }) => {
				return (
					<div
						className="text-center btn_tool"
						style={{ cursor: "pointer" }}
						onClick={showModal("refund", row.original)}
					>
						<MoneyOffIcon />
					</div>
				);
			},
			disableSortBy: true,
		},
	];

	const columns = state?.user?.type === "admin" ? adminColumns : normalColumns;

	React.useEffect(() => {
		reload();
	}, []);

	const reload = () => {
		api_getAllDepositRecord()
			.then((res) => {
				setSourceData(res.data);
				setTableData(res.data);
				setLoading(false);
			})
			.catch((error) => {
				alert(error?.response ? error.response.data.msg : error);
			});
	};

	const onPhoneChange = (e) => {
		setPhone(e.target.value);
	};

	const onSearch = () => {
		const queryData = sourceData.filter((i) => i.phone === phone);
		setTableData(queryData);
	};

	const onReset = () => {
		setTableData(sourceData);
		setPhone("");
	};

	const onRefund = (row) => {
		const data = {
			id: row.ID,
		};
		api_rechargeRefund(data)
			.then((res) => {
				if (res.data.status === "ok") {
					reload();
					closeModal();
				}
			})
			.catch((error) => {
				alert(error?.response ? error.response.data.msg : error);
			});
	};

	const showModal = (type, data) => {
		return () => {
			switch (type) {
				case "refund":
					setRefundModalShow(true);
					setCurrentData(data);
					break;
				default:
					break;
			}
		};
	};

	const closeModal = () => {
		setRefundModalShow(false);
	};

	const resetData = () => {
		setCurrentData(null);
	};

	return (
		<Container Tabs={ConsumerTabs} tabIndex={1}>
			<Form>
				<Form.Row className="align-items-center">
					<Col xs="auto">
						<InputGroup>
							<InputGroup.Prepend>
								<InputGroup.Text className={classes.borderReset}>手機</InputGroup.Text>
							</InputGroup.Prepend>
							<FormControl
								placeholder="請輸入手機號碼"
								className={`${classes.inputBox} ${classes.borderReset}`}
								value={phone}
								onChange={onPhoneChange}
							/>
						</InputGroup>
					</Col>
					<Col xs="auto">
						<Button variant="info" className={classes.borderReset} onClick={onSearch}>
							查詢帳號
						</Button>
					</Col>
					<Col xs="auto">
						<IconButton className={classes.resetButton} onClick={onReset} size="small">
							<AutorenewIcon />
						</IconButton>
					</Col>
				</Form.Row>
			</Form>
			<Divider className="mt-3 mb-4" />
			<Row>
				<div className="col-12">
					<div className="work_area">
						<Table columns={columns} data={tableData} loading={loading} />
					</div>
				</div>
			</Row>
			<NormalModal
				show={refundModalShow}
				title={`退款`}
				onSubmit={onRefund}
				onClose={closeModal}
				onExited={resetData}
				centered
			>
				<p>
					{`確定退款金額`}
					<b className="ml-2">{currentData?.TradeAmount}</b>
				</p>
			</NormalModal>
		</Container>
	);
}

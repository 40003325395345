import React from "react";
import { ConsumerTabs } from "../../layout/Tabs/ConsumerTabs";
import Container from "../../layout/Container";
import { makeStyles, Divider, IconButton } from "@material-ui/core";
import { Form, Col, Row, Button, InputGroup, FormControl } from "react-bootstrap";
import Table from "../../components/common/Table";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import {
	api_getAllEmployeeChargeRecord,
	api_transactionRefund,
	api_addEmployeeChargeRecord,
	api_getAllUsers,
	api_getAllEmployees,
} from "../../API";
import { AuthContext } from "../../UserAuthContext";
import NormalModal from "../../components/modals/NormalModal";
import DatePicker from "react-datepicker";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { formateDateToUTC_8 } from "../../utils/date";

const useStyle = makeStyles((theme) => ({
	borderReset: {
		borderRadius: 2,
	},
	resetButton: {
		border: "1px solid #ced4da",
		borderRadius: 2,
		color: "rgba(0, 0, 0, 0.38)",
		"&:active": {
			border: "1px solid #ced4da",
		},
	},
	dateTimeInput: {
		border: "1px solid #ced4da",
		width: "100%",
		padding: "5px 10px",
		color: "#495057",
	},
	autoCompleteInput: {
		color: "#495057",
		backgroundColor: "#fff",
		border: "1px solid #ced4da",
		width: "100%",
		padding: "0.375rem 0.75rem",
	},
}));

export function Consumption(props) {
	const classes = useStyle();
	const [users, setUsers] = React.useState([]);
	const [employees, setEmployees] = React.useState([]);
	const [phone, setPhone] = React.useState("");
	const [sourceData, setSourceData] = React.useState([]);
	const [tableData, setTableData] = React.useState([]);
	const [loading, setLoading] = React.useState(true);
	const [currentData, setCurrentData] = React.useState(null);
	const [addRecordModalShow, setAddRecordModalShow] = React.useState(false);
	const [refundModalShow, setRefundModalShow] = React.useState(false);
	const [startDate, setStartDate] = React.useState(new Date());
	const { state } = React.useContext(AuthContext);

	const normalColumns = [
		{
			Header: "帳號",
			extraClass: "text-center",
			accessor: "payer",
			disableSortBy: true,
		},
		{
			Header: "手機",
			extraClass: "text-center",
			accessor: "phone",
			disableSortBy: true,
		},
		{
			Header: "交易日期",
			extraClass: "text-center",
			accessor: "time",
			Cell: ({ value }) => {
				return (
					<div className="d-flex  justify-content-center align-items-center " style={{ gap: '1rem' }}>
						{value === undefined ? '' : formateDateToUTC_8(value)}
					</div>
				)
			},
			disableSortBy: true,
		},
		{
			Header: "交易對象",
			extraClass: "text-center",
			accessor: "payee",
			disableSortBy: true,
		},
		{
			Header: "交易金額",
			extraClass: "text-center",
			accessor: "transaction_point",
			disableSortBy: true,
		},
		{
			Header: "原因",
			extraClass: "text-center",
			accessor: "reason",
			disableSortBy: true,
		},
		{
			Header: "備註",
			extraClass: "text-center",
			accessor: "remarks",
			disableSortBy: true,
		},
		{
			Header: "成本",
			extraClass: "text-center",
			accessor: "cost",
			disableSortBy: true,
		},
		{
			Header: "退款狀態",
			extraClass: "text-center",
			accessor: (row) => (row.refund_status ? "已退款" : ""),
			disableSortBy: true,
		},
	];

	const adminColumns = [
		...normalColumns,
		{
			Header: "退款",
			accessor: (row) => row,
			Cell: ({ row }) => {
				return (
					<div
						className="text-center btn_tool"
						style={{ cursor: "pointer" }}
						onClick={showModal("refund", row.original)}
					>
						<MoneyOffIcon />
					</div>
				);
			},
			disableSortBy: true,
		},
	];

	const columns = state?.user?.type === "admin" ? adminColumns : normalColumns;

	React.useEffect(() => {
		reload();
	}, []);

	const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
		<button className="example-custom-input" onClick={onClick} ref={ref}>
			{value}
		</button>
	));

	const reload = () => {
		api_getAllUsers()
			.then((res) => {
				setUsers(res.data);
			})
			.catch((error) => {
				alert(error?.response ? error.response.data.msg : error);
			});
		api_getAllEmployees()
			.then((res) => {
				setEmployees(res.data);
			})
			.catch((error) => {
				alert(error?.response ? error.response.data.msg : error);
			});
		api_getAllEmployeeChargeRecord()
			.then((res) => {
				setSourceData(res.data);
				setTableData(res.data);
				setLoading(false);
			})
			.catch((error) => {
				alert(error?.response ? error.response.data.msg : error);
			});
	};

	const onPhoneChange = (e) => {
		setPhone(e.target.value);
	};

	const onSearch = () => {
		const queryData = sourceData.filter((i) => i.phone === phone);
		setTableData(queryData);
	};

	const onReset = () => {
		setTableData(sourceData);
		setPhone("");
	};

	const onRefund = (row) => {
		const data = {
			id: row.ID,
		};
		api_transactionRefund(data)
			.then((res) => {
				if (res.data.status === "ok") {
					reload();
					closeModal();
				}
			})
			.catch((error) => {
				alert(error?.response ? error.response.data.msg : error);
			});
	};

	const onAddChargeRecord = () => {
		const { cost, transaction_point } = currentData;
		const data = {
			...currentData,
			// payer: currentData?.payer?.userID,
			payee: currentData?.payee?.userID,
			cost: isNaN(parseInt(cost)) ? 0 : parseInt(cost),
			transaction_point: isNaN(parseInt(transaction_point)) ? 0 : parseInt(transaction_point),
			time: startDate.toString(),
		};

		console.log(data);
		api_addEmployeeChargeRecord(data)
			.then((res) => {
				if (res.data.status === "ok") {
					reload();
					closeModal();
				}
			})
			.catch((error) => {
				alert(error?.response ? error.response.data.msg : error);
			});
	};

	const onCurrentDataChange = (key) => {
		return (e) => {
			setCurrentData({
				...currentData,
				[key]: e.target.value,
			});
		};
	};

	const onAutoCompleteInputChange = (key) => {
		return (e, value) => {
			setCurrentData({
				...currentData,
				[key]: value,
			});
		};
	};

	const showModal = (type, data) => {
		return () => {
			switch (type) {
				case "addRecord":
					setAddRecordModalShow(true);
					setStartDate(new Date());
					break;
				case "refund":
					setRefundModalShow(true);
					setCurrentData(data);
				default:
					break;
			}
		};
	};

	const closeModal = () => {
		setAddRecordModalShow(false);
		setRefundModalShow(false);
	};

	const clearData = () => {
		setCurrentData(null);
	};

	return (
		<Container Tabs={ConsumerTabs} tabIndex={2}>
			<Form>
				<Form.Row className="align-items-center">
					<Col xs="auto">
						<InputGroup>
							<InputGroup.Prepend>
								<InputGroup.Text className={classes.borderReset}>手機</InputGroup.Text>
							</InputGroup.Prepend>
							<FormControl
								placeholder="請輸入手機號碼"
								className={`${classes.inputBox} ${classes.borderReset}`}
								value={phone}
								onChange={onPhoneChange}
							/>
						</InputGroup>
					</Col>
					<Col xs="auto">
						<Button variant="info" className={classes.borderReset} onClick={onSearch}>
							查詢帳號
						</Button>
					</Col>
					<Col xs="auto">
						<IconButton className={classes.resetButton} onClick={onReset} size="small">
							<AutorenewIcon />
						</IconButton>
					</Col>
					{state?.user?.type === "admin" && (
						<Col xs="auto" className="ml-auto">
							<Button
								variant="warning"
								className={classes.borderReset}
								onClick={showModal("addRecord")}
							>
								新增交易紀錄
							</Button>
						</Col>
					)}
				</Form.Row>
			</Form>
			<Divider className="mt-3 mb-4" />
			<Row>
				<div className="col-12">
					<div className="work_area">
						<Table columns={columns} data={tableData} loading={loading} />
					</div>
				</div>
			</Row>
			<NormalModal
				show={addRecordModalShow}
				title={"新增消費紀錄"}
				onSubmit={onAddChargeRecord}
				onClose={closeModal}
				onExited={clearData}
				centered
			>
				<Form>
					{/* <Form.Group as={Row}>
						<Form.Label column sm={3}>
							帳號
						</Form.Label>
						<Col sm={9}>
							<Autocomplete
								options={users}
								getOptionLabel={(option) => option?.name || ""}
								onChange={onAutoCompleteInputChange("payer")}
								renderInput={(params) => (
									<div ref={params.InputProps.ref} className="w-100">
										<input
											{...params.inputProps}
											className={classes.autoCompleteInput}
											type="text"
											placeholder="請輸入帳號"
										/>
									</div>
								)}
							/>
						</Col>
					</Form.Group> */}
					<Form.Group as={Row}>
						<Form.Label column sm={3}>
							手機
						</Form.Label>
						<Col sm={9}>
							<FormControl
								placeholder="請輸入手機"
								value={currentData?.phone || ""}
								onChange={onCurrentDataChange("phone")}
							/>
						</Col>
					</Form.Group>
					<Form.Group as={Row}>
						<Form.Label column sm={3}>
							交易日期
						</Form.Label>
						<Col sm={9}>
							<DatePicker
								selected={startDate}
								onChange={(date) => setStartDate(date)}
								timeInputLabel="Time:"
								dateFormat="yyyy/MM/dd h:mm aa"
								showTimeInput
								className={classes.dateTimeInput}
								wrapperClassName="w-100"
							/>
						</Col>
					</Form.Group>
					<Form.Group as={Row}>
						<Form.Label column sm={3}>
							交易對象
						</Form.Label>
						<Col sm={9}>
							<Autocomplete
								options={employees}
								getOptionLabel={(option) => option?.name || ""}
								onChange={onAutoCompleteInputChange("payee")}
								renderInput={(params) => (
									<div ref={params.InputProps.ref} className="w-100">
										<input
											{...params.inputProps}
											className={classes.autoCompleteInput}
											type="text"
											placeholder="請選擇交易對象"
										/>
									</div>
								)}
							/>
						</Col>
					</Form.Group>
					<Form.Group as={Row}>
						<Form.Label column sm={3}>
							交易金額(鑽石)
						</Form.Label>
						<Col sm={9}>
							<FormControl
								placeholder="請輸入交易金額"
								value={currentData?.transaction_point || ""}
								onChange={onCurrentDataChange("transaction_point")}
							/>
						</Col>
					</Form.Group>
					<Form.Group as={Row}>
						<Form.Label column sm={3}>
							原因
						</Form.Label>
						<Col sm={9}>
							<FormControl
								placeholder="請輸入原因"
								value={currentData?.reason || ""}
								onChange={onCurrentDataChange("reason")}
							/>
						</Col>
					</Form.Group>
					<Form.Group as={Row}>
						<Form.Label column sm={3}>
							備註
						</Form.Label>
						<Col sm={9}>
							<FormControl
								placeholder="請輸入備註"
								value={currentData?.remarks || ""}
								onChange={onCurrentDataChange("remarks")}
							/>
						</Col>
					</Form.Group>
					<Form.Group as={Row}>
						<Form.Label column sm={3}>
							成本
						</Form.Label>
						<Col sm={9}>
							<FormControl
								placeholder="請輸入成本"
								value={currentData?.cost || ""}
								onChange={onCurrentDataChange("cost")}
							/>
						</Col>
					</Form.Group>
				</Form>
			</NormalModal>
			<NormalModal
				show={refundModalShow}
				title={`退款`}
				onSubmit={onRefund}
				onClose={closeModal}
				onExited={clearData}
				centered
			>
				<p>
					{`確定退款金額`}
					<b className="ml-2">{currentData?.TradeAmount}</b>
				</p>
			</NormalModal>
		</Container>
	);
}

import React from "react";
import { Modal } from "react-bootstrap";

function NormalModal({
	show,
	children,
	title,
	submitText = "確認",
	closeText = "取消",
	onClose,
	onSubmit,
	onExited,
	centered,
}) {
	return (
		<Modal
			className="modal_style fade"
			size="lg"
			show={show}
			onHide={onClose}
			onExited={onExited}
			centered={centered}
		>
			<Modal.Header closeButton>
				<Modal.Title as="h5">
					<b>{title}</b>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>{children}</Modal.Body>
			<Modal.Footer>
				<div className="d-flex justify-content-end">
					<button type="button" className="btn btn_common btn_add" onClick={onSubmit}>
						{submitText}
					</button>
					<button
						id="close_add"
						type="button"
						className="btn btn_common btn_cancel"
						onClick={onClose}
					>
						{closeText}
					</button>
				</div>
			</Modal.Footer>
		</Modal>
	);
}

export default NormalModal;

import React from "react";
import { Link } from "react-router-dom";

export default function Tabs(props) {
	const { tabData, currIndex } = props;

	const getClass = (tabIndex) => {
		if (currIndex === tabIndex) {
			return "active";
		} else {
			return "";
		}
	};

	return (
		<ul className="nav nav-tabs tabs_area clearfix">
			{tabData.map((item, key) => {
				return (
					<li key={key} className="nav-item">
						<Link
							key={key}
							to={`${item.path}`}
							className={"nav-link " + getClass(item.tabIndex)}
						>
							{item.tabName}
						</Link>
					</li>
				);
			})}
		</ul>
	);
}

import React from "react";
import { Link, useHistory } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { api_login } from "../API";
import { AuthContext } from "../UserAuthContext";

export function Login() {
	const [email, set_email] = React.useState(null);
	const [password, set_password] = React.useState(null);
	const [keep_login, set_keep_login] = React.useState(false);
	const history = useHistory();
	const { checkToken } = React.useContext(AuthContext);

	const login = async () => {
		const data = {
			account: email,
			password: password,
		};
		api_login(data)
			.then((res) => {
				console.log(res.data);
				localStorage.setItem("dct-token", res.data.token);
				checkToken();
			})
			.catch((error) => {
				alert(error?.response ? error.response.data.msg : error);
			});
	};

	const handle_change_email = (e) => {
		if (e.target.value === "") {
			set_email(null);
		} else {
			set_email(e.target.value);
		}
	};

	const handle_change_password = (e) => {
		if (e.target.value === "") {
			set_password(null);
		} else {
			set_password(e.target.value);
		}
	};

	const handle_checked = (e) => {
		set_keep_login(!keep_login);
	};

	//驗證是否有登入
	const verify = async () => {
		try {
		} catch (error) {}
	};

	React.useEffect(() => {
		verify();
	}, []);

	//監聽全域enter事件
	React.useEffect(() => {
		document.addEventListener("keydown", enter_login);
		return () => {
			document.removeEventListener("keydown", enter_login);
		};
	}, [email, password]);

	//登入enter按鈕
	const enter_login = (e) => {
		if (e.keyCode === 13) {
			login();
		}
	};

	return (
		<div className="wrapper_area login_area">
			<main className="main_area clearfix">
				<div className="login_content clearfix">
					<div className="login_bg d-flex">
						<div className="login_left_logo d-flex align-items-center justify-content-center">
							<img className="login_logo bg-white" src="./img/pv_logo.png" alt="" />
						</div>
						<div className="login_inner clearfix">
							<div className="login_form_bg d-flex align-items-center justify-content-center">
								<div className="login_form_block w-100">
									<Form className="login-form" action="">
										<Form.Group>
											<h3>使用者登入</h3>
										</Form.Group>
										<Form.Group controlId="login_email">
											<Form.Label className="lab_title">電子郵件</Form.Label>
											<Form.Control
												type="text"
												className="ipt_data"
												onChange={handle_change_email}
											/>
											<div className="help_block d-none">
												<i className="float-left icon-error_outline"></i>
												<span>此為必填欄位。</span>
											</div>
										</Form.Group>
										<Form.Group controlId="login_password">
											<Form.Label className="lab_title">密碼</Form.Label>
											<Form.Control
												type="password"
												className="ipt_data"
												onChange={handle_change_password}
											/>
											<div className="help_block d-none">
												<i className="float-left icon-error_outline"></i>
												<span>此為必填欄位。</span>
											</div>
										</Form.Group>
										<Form.Group controlId="keep_login">
											<div className="checkbox_area clearfix">
												<input
													type="checkbox"
													className="form-check-input d-none"
													id="keep_login"
													onChange={handle_checked}
												/>
												<label className="form-check-label" htmlFor="keep_login">
													<b>保持登入</b>
												</label>
											</div>
										</Form.Group>
										<Form.Group>
											<Link to="/home">
												<button
													type="button"
													className="btn btn_submit text-center w-100"
													onClick={login}
												>
													<i className="icon-httpslock"></i>
													<span>登入</span>
												</button>
											</Link>
										</Form.Group>
									</Form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</main>
		</div>
	);
}
